import { makeAutoObservable } from "mobx";

class AuthStore {
    constructor() {
        makeAutoObservable(this);
    }

    address = null;
    nearID = null;
    loginType = null;
    userJWT = null;
    activeMeetingLink = null;
    accountId = null;
    contractId = null;
    publicKey = null;
    metaKey = null;
    

    getAddress() {
        return this.address
    }

    getLoginType() {
        return this.loginType;
    }

    getActiveMeetingLink() {
        return this.activeMeetingLink
    }

    getUserJWT() {
        return this.userJWT;
    }

    getUserData() {
        return {
            loginType: this.loginType,
            address: this.address,
            nearID: this.nearID,
            publicKey: this.publicKey
        }
    }

    setAddress(addr) {
        this.address = addr
    }

    setUserJWT (token) {
        this.userJWT = token
    }

    setActiveMeetingLink(link) {
        this.activeMeetingLink = link;
    }

    setUserData(loginType, address, nearID, publicKey, metaKey ) {
        this.nearID = nearID || null;
        this.publicKey = publicKey || null;
        this.loginType = loginType;
        this.address = address;
        //for test purposes only
        //this.address = "mosw.testnet";
      
        // console.log(this.loginType, this.address, this.publicKey);
    }

    logOut() {
      this.setUserData(null, null, null);
      this.setAddress(null);
      this.setUserJWT(null);
      window.localStorage.clear();
      window.location.reload();
    }
}

const authStoreInstance = new AuthStore();

export default authStoreInstance;