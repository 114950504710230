import "./index.sass";

import deactivateIcon from "../../assets/icons/deactivate-icon.svg";
import disabledIcon from "../../assets/icons/disabled-icon.svg";
import { formatNumber } from "../../functions/utils";
import { useEffect, useState } from "react";
import { changeNodeState } from "../../services/nodes";
import { CrownIcon } from "./icon";

export default function NodeListItem({
  node,
  getCurrentNode,
  selectedNode,
  width,
  userData,
  isNodeOwner,
  rankingPosition,
  isAdminUser
}) {
  // // This state was created to break the ownerId information on small screens (-620px) by the dot(.) character.
  // const [ownerId, setOwnerId] = useState({
  //   firstWord: node.ownerId ? node.ownerId : "",
  //   secondWord: "",
  // });
  // Added a loading state so once the button is clicked the user can only click it again after a while.
  const [isLoading, setIsLoading] = useState(false);

  // Created this state to control the node state update and so don't need to call the API to update the nodes list
  const [currentNode, setCurrentNode] = useState(node);

  // Through this function, the user can select and deselect the current node by clicking on a specific node in the list.
  const handleSelectNode = () => {
    if (selectedNode) {
      getCurrentNode(undefined);
    } else {
      getCurrentNode(currentNode);
    }
  };

  const renderInfo = (title, data) => (
    <div className="info-item">
      <strong>{title}:</strong> <span>{data}</span>
    </div>
  );

  // This function will call API to change the current node state
  // and will update the node status based on the API response list
  const handleChangeNodeStatus = async (nodeId, status) => {
    try {
      setIsLoading(true);
      const updatedNodesList = await changeNodeState(nodeId, status);
      if (updatedNodesList) {
        const updatedNode = updatedNodesList.find(
          (nodeInUpdated) => nodeInUpdated.nodeId === currentNode.nodeId
        );
        updatedNode && setCurrentNode(updatedNode);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [formattedOwnerId, setFormattedOwnerId] = useState("");

  useEffect(() => {
    if (node.ownerId) {
      const parts = node.ownerId.split('.testnet');
      const mainPart = parts[0];
  
      if (mainPart.length > 10) {
        const start = mainPart.substring(0, 5);
        const end = mainPart.substring(mainPart.length - 5);
        setFormattedOwnerId(`${start}...${end}`);
      } else {
        setFormattedOwnerId(mainPart);
      }
    } else {
      setFormattedOwnerId("");
    }
  }, [node.ownerId]);
  
  

  // Insert class on element according to the state of the node
  const getNodeStatus = (state) => {
    const statusMap = {
      Active: "node_item-status--green",
      Inactive: "node_item-status--red",
      Disabled: "node_item-status--yellow",
    };
    return statusMap[state] || "node_status_signal-gray";
  };

  // Insert the class inside the span element
  // to change the circle background color according to the state of the node
  const selectClassByNodeState = (state) => {
    const statusMap = {
      Active: "node_item-row-unit-state--green",
      Inactive: "node_item-row-unit-state--red",
      Disabled: "node_item-row-unit-state--yellow",
    };
    return statusMap[state] || "node_item-row-unit-state";
  };

  const getDisplayStatus = (state) => {
    switch (state) {
      case "Active":
        return "Online";
      case "Inactive":
        return "Offline";
      default:
        return state;
    }
  };

  const renderCrownByPosition = (position) => {
    switch (position) {
      case "1":
        return (
          <CrownIcon className="node_item-row-ranking node_item-row-ranking--gold" />
        );
      case "2":
        return (
          <CrownIcon className="node_item-row-ranking node_item-row-ranking--silver" />
        );
      case "3":    
        return (
          <CrownIcon className="node_item-row-ranking node_item-row-ranking--bronze" />
        );
      default:
        return null;
    }
  }

  return (
    <div className="node_item">
      <div
        key={node.nodeId}
        className={`
          ${
            selectedNode
              ? "node_item-container node_item-container--selected"
              : "node_item-container"
          }
          ${
            !isNodeOwner &&
            selectedNode &&
            "node_item-container--selected-notowner"
          }
        `}
        data-hash={node.nodeId}
        onClick={() => handleSelectNode()}
      >
        <div className="node_item-row-unit">          
        <span className="node_item-row-unit-ownerId">
  {renderCrownByPosition(rankingPosition)}
  {formattedOwnerId}
</span>
          {/* This span element will create the circle behind the ownerId according to the state of the node */}
          <span
            className={`node_item-row-unit-state ${selectClassByNodeState(
              currentNode.state
            )}`}
          />
        </div>
        {/* Created this container and the strong element to provide mobile design adjustments */}
        <div
          className={`node_item-row-container ${
            width <= 420 ? "node_item-row-container--no-mobile" : ""
          }`}
        >
          <strong className="node_item-row-heading">Status</strong>
          <div className={`node_item-row-unit`}>
            <h3 className={getNodeStatus(currentNode.state)}>
              {getDisplayStatus(currentNode.state)}
            </h3>
          </div>
        </div>
        <div
          className={`node_item-row-container ${
            width <= 420 ? "node_item-row-container--no-mobile" : ""
          }`}
        >
          <strong className="node_item-row-heading">Node ID</strong>
          <div className={`node_item-row-unit`}>
            <h3>{currentNode.nodeId.substring(0, 5)}</h3>
          </div>
        </div>
        <div className="node_item-row-container">
          <strong className="node_item-row-heading">Region</strong>
          <div className={`node_item-row-unit`}>
            <h3 className="node_item-status">{node.region}</h3>
          </div>
        </div>
        {/* Created this container and the strong element to provide mobile design adjustments */}
        <div className="node_item-row-container node_item-row-container--no-mobile">
          <strong className="node_item-row-heading">Upload</strong>
          <div className="node_item-row-unit">
            {node.bandwidth?.upload?.speed === undefined ? (
              "N/A"
            ) : (
              <>
                {node.bandwidth?.upload?.speed}
                <span> Mbps</span>
              </>
            )}
          </div>
        </div>
        {/* Created this container and the strong element to provide mobile design adjustments */}
        <div
          className={`node_item-row-container ${
            width <= 620 ? "node_item-row-container--no-mobile" : ""
          }`}
        >
          <strong className="node_item-row-heading">Downloads</strong>
          <div className="node_item-row-unit">
            {node.bandwidth?.download?.speed === undefined ? (
              "N/A"
            ) : (
              <>
                {node.bandwidth?.download?.speed}
                <span> Mbps</span>
              </>
            )}
          </div>
        </div>
        {/* Created this container and the strong element to provide mobile design adjustments */}
        <div className="node_item-row-container">
          <strong className="node_item-row-heading">Traffic</strong>
          <div className="node_item-row-unit">
            {node.traffic === "0" ? (
              "0 MB"
            ) : (
              <>{formatNumber(parseFloat(node.traffic))}</>
            )}
          </div>
        </div>
        {isNodeOwner && (
          <div className="node_item-row-container node_item-row-container--no-mobile">
            <strong className="node_item-row-heading"></strong>
            <div className="node_item-row-unit node_item-row-unit-last"> </div>
          </div>
        )}
      </div>

      {isNodeOwner && (
        <div
          className={`${
            selectedNode
              ? "node_item-row-container node_item-row-buttons node_item-row-buttons--selected"
              : "node_item-row-container node_item-row-buttons"
          }`}
        >
          {/* The button will call function based on the node.state if it is active than it will call API passing 'deactivate' param and vice versa */}
          <button
            className="node_item-row-container-button"
            onClick={() => {
              const status =
                currentNode.state === "Active"
                  ? "deactivate"
                  : currentNode.state === "Inactive"
                  ? "activate"
                  : "activate";
              handleChangeNodeStatus(node.nodeId, status);
            }}
            disabled={isLoading || userData.address !== node.ownerId}
          >
            <img
              className="node_item-row-container-button-image"
              src={deactivateIcon}
              alt="Go back arrow"
            />
            <span className="node_item-row-container-button-text">
              {currentNode.state === "Active" ? "Deactivate" : "Activate"}
            </span>
          </button>

          <button
            className="node_item-row-container-button"
            onClick={() => handleChangeNodeStatus(node.nodeId, "disable")}
            disabled={
              currentNode.state === "Disabled" ||
              userData.address !== node.ownerId
            }
          >
            <img
              className="node_item-row-container-button-image"
              src={disabledIcon}
              alt="Not allowed circle"
            />
            <span className="node_item-row-container-button-text">Disable</span>
          </button>
        </div>
      )}
      {selectedNode && <div className="management_card-mobile">
        <div className="management_card-info">
          <div className="management_card-title">
            {selectedNode ? selectedNode.ownerId : "No Node Selected"}
          </div>
          {renderInfo("Owner ID", isAdminUser ? currentNode.ownerId : formattedOwnerId)}
          {currentNode.ip && isAdminUser && renderInfo("IP", currentNode.ip)}
          {renderInfo("Node ID", currentNode.nodeId && isAdminUser ? currentNode.nodeId : currentNode.nodeId ? currentNode.nodeId.substring(0, 5) : "-")}

          {renderInfo(
            "Traffic",
            currentNode.traffic === "0"
              ? "0 MB"
              : `${formatNumber(parseFloat(currentNode.traffic) / 1024.0)}`
          )}
          {renderInfo(
            "Download Speed",
            currentNode.bandwidth?.download?.speed
              ? `${currentNode.bandwidth.download.speed} Mbps`
              : "N/A"
          )}
        </div>
      </div>
}
    </div>
  );
}
