import React from "react";
import ReactDOM from "react-dom/client";
import { MetaMaskProvider } from "metamask-react";
import AppRoutes from "./routes";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.sass";
import { AuthContextProvider } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      {/* <AuthContextProvider mockAddress="zanit.testnet"> */}
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </MetaMaskProvider>
  </React.StrictMode>
);
reportWebVitals();
