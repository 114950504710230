export const CrownIcon = (props) => (
  <svg 
    width="18" 
    height="17" 
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 12.1998L0 1.01665L5.5 6.09989L9 0L12.5 6.09989L18 1.01665L16 12.1998H2ZM16 15.2497C16 15.8597 15.6 16.2664 15 16.2664H3C2.4 16.2664 2 15.8597 2 15.2497V14.2331H16V15.2497Z" />
  </svg>
);