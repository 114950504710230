import Footer from "../../components/Footer";
import PageContainer from "../../components/pageContainer";

export default function Terms() {

  return (
    <PageContainer>
      <section className="privacy-relayz" id="relayz terms">
        <h4
          className="large-title purple-text header__font privacy__box-text-spacing"
        >
          Terms of Use
        </h4>
        <div className="privacy__box home-description-cta-text">
          <h2 className="privacy__box-text-spacing">
            Term of Use
          </h2>
          <p className="privacy__box-text-spacing">
            This end-user agreement (the "Agreement") should be read by you (the
            "User" or "you") in its entirety prior to your use of Relayz’s
            service or products. Be aware that this Agreement constitutes a
            legally binding agreement between you and Relayz Ltd, with address
            at House of Francis, Room 303, Ile Du Port, Mahe, Seychelles,
            (referred to herein as "Relayz"; "us" or "we") which owns and
            operates the website on the Internet and the Service at
            https://relayz.io/ and https://meet.relayz.io/ (the "Service"). By
            accessing or using the site or Relayz Services, you agree that you
            have read, understood, and agree to be bound by this agreement.
          </p>

          <h3 className="privacy__box-text-spacing">
            1. General Conditions of use
          </h3>
          <p className="privacy__box-text-spacing">
            1.1. By signing up to use an Account through any of the Relayz
            Clients’ social websites made available through the Relayz Platform,
            you agree to comply with and be legally bound by this Agreement. If
            you do not agree to any of the terms set forth in this Agreement or
            any subsequent modification to the Agreement, you may not access or
            use any of the Relayz Services and must cancel your Relayz Account
            immediately.
          </p>
          <p className="privacy__box-text-spacing">
            1.2. We may amend or modify this Agreement by posting such amended
            or modified Agreement ("Revised Agreement") on the Relayz Platform
            or by notifying you about the changes via email. By continuing to
            access or use the Relayz Services once the Revised Agreement is
            effective, you agree to be bound by its terms.
          </p>
          <p className="privacy__box-text-spacing">
            1.3. To be eligible to use the Relayz Services, you must be at least
            18 years old (or the applicable age of majority and contractual
            capacity in each qualifying jurisdiction). By accessing or using the
            Relayz Services you represent and warrant that you are 18 or older.
          </p>
          <p className="privacy__box-text-spacing">
            1.4. Relayz disclaims any and all warranties, expressed or implied,
            in connection with the service which is provided to you "as is" and
            we provide you with no warranty or representation whatsoever
            regarding its quality, fitness for purpose, completeness, or
            accuracy.
          </p>
          <p className="privacy__box-text-spacing">
            1.5. The term "Relayz", its domain names and any other trademarks,
            or service marks used by Relayz as part of the Service (the
            "Trademarks"), are solely owned by Relayz. In addition, all content
            on the website, including, but not limited to, the images, pictures,
            graphics, photographs, animations, videos, music, audio, and text
            (the "Site Content") belongs to Relayz and is protected by copyright
            and/or other intellectual property or other rights. You hereby
            acknowledge that by using the Service, you obtain no rights in the
            Site Content and/or the Trademarks, or any part thereof. Under no
            circumstances may you use the Site Content and/or the Trade Marks
            without Relayz’s prior written consent. Additionally, you agree not
            to do anything that will harm or potentially harm the rights,
            including the intellectual property rights of Relayz.
          </p>

          <h3 className="privacy__box-text-spacing">
            2. Authority/Terms of Service
          </h3>

          <p className="privacy__box-text-spacing">
            {" "}
            You agree to the rules of the Service provided and described on the
            https://relayz.io/ and website and https://meet.relayz.io/ Service.
            Relayz retains all authority over the issuing, maintenance, and
            closing of the Service. The decision of Relayz’s management,
            concerning any use of the Service, or dispute resolution, is final
            and shall not be open to review or appeal.
            <h3 className="privacy__box-text-spacing">
              3. Your Representations and Warranties Prior to your use of the
              Service and on an ongoing basis you represent, warrant, covenant,
              and agree that:{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              3.1. There is a risk of losing cryptocurrency & other funds of
              value when using the Service and Relayz has no responsibility to
              you for any such loss;{" "}
            </p>
            <p className="privacy__box-text-spacing">
              3.2. Your use of the Service is at your sole option, discretion,
              and risk. You hereby accept full responsibility for any
              consequences that may arise from your use of the Services, and
              expressly agree and acknowledge that Relayz shall have absolutely
              no liability in this regard.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              3.3. You are solely responsible for any applicable taxes which may
              be payable on cryptocurrency traded or transacted by you through
              your using the Service;{" "}
            </p>
            <p className="privacy__box-text-spacing">
              3.4. Any cryptographic tokens, blockchain technology, or
              distributed ledger technology-related projects are new and
              relatively untested, and outside of both our and our Clients’
              exclusive control. Any adverse changes in market forces,
              technology, and regulatory environment impacting our performance
              under this Agreement shall absolve us from responsibility in this
              regard, including but not limited to hacking attacks, possible
              theft, unfavorable regulatory action, or unclear legal/tax status
              of cryptographic tokens.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              3.5. (i) You are eighteen years of age or older, (ii) you are of
              the age of majority in your jurisdiction, (iii) you are accessing
              the Service from a jurisdiction in which it is legal to do so,
              (iv) your use of the Services is not prohibited by applicable law,
              and at all times compliant with applicable law; and (v) you are
              solely responsible for use of the Services and, if applicable, for
              all activities that occur on or through your User Account.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">
              4. Prohibited Uses & Termination{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              4.1. In connection with your use of the Relayz Services, and your
              interactions with other Users, and third parties you agree and
              represent you will not engage in any illegal, unauthorized, or
              improper activity, which are: Infringe any proprietary rights,
              including but not limited to copyrights, patents, trademarks, or
              trade secrets of Relayz; Create multiple accounts, including for
              collusion and/or abuse of service; Use the Services to transmit
              any data or send or upload any material that contains viruses,
              Trojan horses, worms, time-bombs, keystroke loggers, spyware,
              adware, or any other harmful programs or similar computer code
              designed to adversely affect the operation of any computer
              software or hardware; Make any backup or archival copies of the
              Platform or any part thereof, including disassembling or
              de-compilation of the Platform;
            </p>
            <p className="privacy__box-text-spacing">
              4.2. We reserve the right to (a) modify or discontinue any portion
              of the Relayz Services, and (b) suspend or terminate your access
              to the Relayz Services, at any time, and from time to time,
              without notice to you in certain, limited circumstances described
              herein. You agree that we shall not be liable to you or any third
              party for any modification or termination of the Relayz Services,
              or suspension or termination of your access to the Relayz
              Services, except to the extent otherwise expressly set forth
              herein.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">
              5. Know your Customer ("KYC") and Anti-Money Laundering (AML)
              Policy{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              5.1. Relayz is a permissionless and decentralized platform. As a
              software development company, Relayz has no role in enforcing KYC
              by default.
            </p>
            <p className="privacy__box-text-spacing">
              5.2. Therefore, and at the sole behest of competent regulatory
              authorities Relayz reserves the right at any time to confiscate
              any and all funds that are found to be in violation of relevant
              and applicable anti-money laundering (AML) and countering
              terrorism financing (CFT) laws and regulations, and to cooperate
              with the competent authorities when and if necessary.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              5.3. Relayz reserves the right to reject the use of the Service if
              Relayz has the reasonable ground to believe that the user(s) are
              found to be in violation of relevant and applicable AML/CFT laws
              and regulations, and to cooperate with the competent authorities
              or any investigation when and if necessary upon the valid request
              by the court order.
            </p>
            <p className="privacy__box-text-spacing">
              5.4. Relayz expressly prohibits and rejects the use of the Service
              for any form of illicit activity, including money laundering,
              terrorist financing or trade sanctions violations, consistent with
              various jurisdictions’ laws, regulations and norms. To that end,
              the Service is not offered to individuals or entities on any
              Politically Exposed Persons (“PEP”) lists, or subject to any
              United States, European Union, or other global sanctions or watch
              lists. By using the Service, you represent that you are not on any
              such lists.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              5.5. If Relayz has the reasonable ground to believe that any user
              transacts or use the Services by using the digital currencies
              derived from any suspicious illegal activities, Relayz shall be
              entitled to freeze/close/delete accounts as necessary. Relayz will
              hold no liability to such users for any damage, or loss arising
              out of or in connection with this manner herein. Please note that
              any attempt to circumvent this Clauses will also result in a
              similar action.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">
              6. Retention of Intellectual Property Rights of Relayz Platform
              and Relayz clients{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              {" "}
              6.1. The Relayz Platform and all Relayz Services, including their
              design elements or concepts and any underlying intellectual
              property, including, but not limited to, all trademarks, are the
              property of Relayz and/or Relayz Customers/Partners (as
              applicable), and are protected by copyright, patent, trade secret,
              and other intellectual property laws.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              6.2. Relayz and Relayz Clients retain any and all rights, title,
              and interest in and to Relayz Platform and Relayz Services
              (including, without limitation, all intellectual property rights),
              including all copies, modifications, extensions, and derivative
              works thereof. Your right to use the Relayz Platform and Relayz
              Services is limited to the rights expressly granted in these
              Terms. Except as stated in these Terms, nothing in these Terms
              should be construed as conferring any right in or license to
              Relayz’s or any third party’s intellectual rights.
            </p>
            <p className="privacy__box-text-spacing">
              {" "}
              6.3. You may not: Copy, create derivative works, distribute,
              publish, reverse engineer, decompile, disassemble, modify, or
              translate the Relayz website or the Service; or Use the Service
              that in any way is prohibited by applicable laws or regulations
              (each of the above herein defined as an "Unauthorized Use"). You
              agree that you will be solely responsible for any damage, costs,
              or expenses arising out of or in connection with any unauthorized
              use by you{" "}
            </p>
            <h3 className="privacy__box-text-spacing">
              7. Jurisdiction and Governing Law{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              7.1. The laws of Republic of Seychelles (with the exclusion of any
              rules that might lead to the use of any other law which is not the
              law of Republic of Seychelles) will govern the validity and
              construction of this Agreement, any separate agreement whereby we
              provide you any services and any dispute arising out of or in
              relation to this Agreement or such separate agreement.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              7.2. Persons located in or residents of Algeria, Belarus, Bolivia,
              Cambodia, Colombia, Ecuador, Egypt, Iran, Iraq, Libya, Morocco,
              Nepal, Sudan, Syria, Ivory Coast, Zimbabwe, Liberia, Myanmar,
              North Korea, The Crimea, (the "Prohibited Jurisdictions") are not
              permitted to make use of the Service. For the avoidance of doubt,
              the foregoing restrictions on Service from Prohibited
              Jurisdictions apply equally to residents and citizens of other
              nations while located in a Prohibited Jurisdiction. Any attempt to
              circumvent the restrictions on usage by any persons located in a
              Prohibited Jurisdiction or Restricted Jurisdiction is a breach of
              this Agreement. An attempt at circumvention includes, but is not
              limited to, manipulating the information used by Relayz to
              identify your location and providing Relayz with false or
              misleading information regarding your location or place of
              residence.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">
              8. Third-party services{" "}
            </h3>
            <p className="privacy__box-text-spacing">
              8.1. The Services may include services, content, and information
              owned, made available, or otherwise licensed by a third party
              ("Third-Party Services") or contain links to Third Party Services.
              You understand that Third-Party Services are the responsibility of
              the third party that created or provided it and acknowledges that
              the use of such Third Party Services is solely at your own risk.
            </p>
            <p className="privacy__box-text-spacing">
              8.2. Relayz makes no representations and excludes all warranties
              and liabilities arising out of or pertaining to such Third Party
              Services, including its accuracy or completeness.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              8.3. All intellectual property rights in and to Third-Party
              Services are the property of the respective third parties.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">9. Relayz token </h3>
            <p className="privacy__box-text-spacing">
              9.1. Relayz will issue a blockchain-based token called "Relayz
              Token". Relayz Token is classified as the utility token designed
              to be used solely on the Relayz Platform or on https://Relayz.io/
              website.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              9.2. Users who stake Relayz Tokens in their wallets will be
              eligible to use some of the platform services.
            </p>
            <p className="privacy__box-text-spacing">
              9.3. Relayz Token is not considered as security of any kind, and
              it also does not carry any right to vote, manage or share in the
              Relayz Platform.
            </p>
            <p className="privacy__box-text-spacing">
              9.4. Relayz Token is neither money nor legal tender/currency,
              whether fiat or otherwise, and it does not carry any value whether
              it is intrinsic or assigned.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">10. Breach </h3>
            <p className="privacy__box-text-spacing">
              {" "}
              10.1. Without prejudice to any other rights, if a User breaches in
              whole or in part any provision contained herein, Relayz reserves
              the right to take such action as it sees fit, including
              terminating this Agreement or any other agreement in place with
              the User and/or taking legal action against such User.{" "}
            </p>
            <p className="privacy__box-text-spacing">
              10.2. You agree to indemnify and hold harmless Relayz, its
              affiliates, subsidiaries, licensors, and their respective
              directors, officers, members, managers, employees, and agents from
              and against any and all claims and expenses arising out of your
              use of the Services, a breach of any provision of these Terms by
              you or any person using the Services on your behalf, a breach by
              you of any applicable laws, or any third-party claim to the extent
              arising from or connected with an allegation that your use of the
              Services in accordance with these Terms infringes any rights of a
              third party.{" "}
            </p>
            <h3 className="privacy__box-text-spacing">11. Force Majeure </h3>
            <p className="privacy__box-text-spacing">
              11.1. Relayz shall have no liability for delays, failure in
              performance, or interruption of service which result directly or
              indirectly from any cause or condition beyond our reasonable
              control, including but not limited to, any delay or failure due to
              any act of God, the act of civil or military authorities, the act
              of terrorists, civil disturbance, war, strike or other labor
              dispute, fire, interruption in telecommunications or Internet
              services or network provider services, failure of equipment and/or
              software, other catastrophe or any other occurrence which is
              beyond our reasonable control and shall not affect the validity
              and enforceability of any remaining provisions.
            </p>
            <p className="privacy__box-text-spacing">
              11.2. You agree to indemnify and hold harmless Relayz, its
              affiliates, subsidiaries, licensors, and their respective
              directors, officers, members, managers, employees, and agents from
              and against any and all claims and expenses arising out of your
              use of the Services, a breach of any provision of these Terms by
              you or any person using the Services on your behalf, a breach by
              you of any applicable laws, or any third-party claim to the extent
              arising from or connected with an allegation that your use of the
              Services in accordance with these Terms infringes any rights of a
              third party.
            </p>
            <h3 className="privacy__box-text-spacing">12. Miscellaneous </h3>
            <p className="privacy__box-text-spacing">
              12.1. Severability: If a provision of this
            </p>
            Agreement is or becomes illegal, invalid, or unenforceable in any
            jurisdiction, that shall not affect the validity or enforceability
            in that jurisdiction of any other provision hereof or the validity
            or enforceability in other jurisdictions of that or any other
            provision hereof.{" "}
          </p>
          <p className="privacy__box-text-spacing">
            12.2. Assignment: Relayz reserves the right to assign this
            Agreement, in whole or in part, at any time without notice. The User
            may not assign any of his/her rights or obligations under this
            Agreement.{" "}
          </p>
          <p className="privacy__box-text-spacing">
            12.3. Third-Party Rights: Unless expressly provided to the contrary
            in this Agreement, a person who is not a party to this Agreement has
            no right to enforce or to enjoy the benefit of any term of this
            Agreement. Notwithstanding any term of this Agreement, no consent of
            any party who is not a party to this Agreement shall be required for
            the waiver, variation, or termination of any part of this Agreement.{" "}
          </p>
          <p className="privacy__box-text-spacing">
            12.4. Support and Notice: All notices, requests, demands, and
            determinations for Relayz under these Terms (other than routine
            operational communications) shall be sent to [terms@relayz.io].
          </p>
        </div>
      </section>
      <Footer />
    </PageContainer>
  );
}
