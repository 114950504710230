import "./index.sass";
import { ReactComponent as Logo } from "../../assets/images/relayz-logo-small.svg";
import { ReactComponent as MailIcon } from "../../assets/images/mail-icon.svg";
import useWindowDimensions from "../../functions/useWindowDimensions";
import useGetSocial from "../../functions/useGetSocial";

export default function Footer() {
  const { width } = useWindowDimensions();
  const social = useGetSocial();
  const isMobile = width < 768; // Adjust the breakpoint as needed

  return (
    <footer className="footer">
      {isMobile && <hr className="footer-hr" />}
      <div className="footer-link-wrapper footer-link-wrapper-email">
        {isMobile ? (
          <MailIcon className="footer-link-icon" />
        ) : (
          <Logo className="footer-logo" />
        )}
        <a href="mailto:info@relayz.io" className="footer-link">
          info@relayz.io
        </a>
      </div>

      <div className="footer-link-wrapper">
        <a href="/terms" className="footer-link">
          Terms of use
        </a>
        <a href="/privacy" className="footer-link">
          Privacy
        </a>
      </div>
      <div className="footer-link-wrapper">
        <div style={{ minWidth: "8.5rem" }} className="footer-link purple-text">
          ©2023 Relayz.Ltd
        </div>
      </div>

      <div>
        <h4
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "15rem",
          }}
        >
          {social.map((soc, index) => {
            let key;
            switch (index) {
              case 0:
                key = "twitter";
                break;
              case 1:
                key = "discord";
                break;
              case 2:
                key = "telegram";
                break;
              case 3:
                key = "moodle";
                break;
              case 4:
                key = "linkedin";
                break;
              default:
                key = `social-${index}`;
            }

            return (
              <a 
              href={soc.url} 
              key={key}
              target={!isMobile ? "_blank" : undefined} 
              rel={!isMobile ? "noopener noreferrer" : undefined}
            >
              <img
                style={{ height: "25px" }}
                src={soc.image}
                alt={`social ${key}`}
              />
            </a>
            );
          })}
        </h4>
      </div>
    </footer>
  );
}
