import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import { useAuthContext } from "../../contexts/AuthContext";
import useWindowDimensions from "../../functions/useWindowDimensions";
import {
  subMenuAnimation,
  subMenuItemAnimation,
} from "../../functions/animations";
//import { ComputerIcon, GiftIcon, BoltIcon, DoorOpenIcon, PlusCircleIcon } from "./icons";
import { ComputerIcon, GiftIcon, DoorOpenIcon, PlusCircleIcon, NewsIcon } from "./icons";
import relayzGrayIcon from "../../assets/icons/relayz-gray-icon.svg";
import "./index.sass";

const links = [
  {
    title: "Manage Profile",
    to: "/nodemanagement/userprofile",
    type: "profile",
  },
  { title: "News management", to: "/newsmanagement", type: "profile" },
  { title: "Reward Programs", to: "/invite", type: "profile" },
  { title: "Relayz Meet", to: "https://meet.relayz.io", type: "meet" },
  // { title: "Start quick Relayz Meet", to: "https://meet.relayz.io/myspecialroom", type: "meet" },
  { title: "Log Out", to: "", type: "profile" },
];

const MenuItems = ({ links, showMenu, logOut, isAdmin }) => {
  if (links && links.length === 0) return null;

  const renderIcon = (title) => {
    switch (title) {
      case "Manage Profile":
        return <ComputerIcon width={14}  />;
      case "Reward Programs":
        return <GiftIcon width={14}  />;
      case "Create Relayz Meet":
        return <PlusCircleIcon width={14}  />;
      case "News management":
        return <NewsIcon width={14} fill="#FFB432"  />;
      // case "Start quick Relayz Meet":
      //   return <BoltIcon width={12} height={'auto'} />
      case "Log Out":
        return <DoorOpenIcon width={12} />;
      default:
        return;
    }
  };

  // localStorage can't be shared between applications

  // const handleQuickMeeting = () => {
  //   const meetingData = {
  //     meetingName: "myspecialroom",
  //     userName: "John Doe"
  //   }
  //   localStorage.setItem("meetingData", JSON.stringify(meetingData));
  //   window.location.href = "https://meet.relayz.io/myspecialroom";
  // }

  return (
    <motion.aside
      className="header__float-menu"
      variants={subMenuAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {links?.map((link, index) => {
        if (link.title === "News management" && !isAdmin) {
          return null; // se o usuário não for admin e o título for "News management", não renderizar o link
        }
        return (
          <motion.div
            key={link.title}
            variants={subMenuItemAnimation}
            initial="initial"
            animate={{
              opacity: 1,
              transition: {
                opacity: { delay: 0.3 * (index / 2), duration: 0.5 },
              },
            }}
            exit="exit"
          >            
            {link.title === "Log Out" ? (
              // Renders sign out button instead of link
              <button
                className="header__float-menu-item header__float-menu-item-signout"
                onClick={() => logOut()}
              >
                {renderIcon(link.title)}
                {link.title}
              </button>
            ) : link.title === "Reward Programs" ? ( // Check if the link is for "Reward Programs"
              <NavLink
                to={link.to}
                className="header__float-menu-item"
                onClick={() => showMenu(false)}
              >
                {renderIcon(link.title)}
                {link.title}
              </NavLink>
            ) : (
              <NavLink
                to={link.to}
                className="header__float-menu-item"
                onClick={() => showMenu(false)}
              >
                {renderIcon(link.title)}
                {link.type === "meet"
                  ? link.title
                      .split(" ")
                      .map((word) => (
                        <span key={word}>  
                          {word === "Relayz" ? (
                            <span className="purple-text">Relayz</span>
                          ) : (
                            ` ${word}`
                          )}
                        </span>
                      ))
                  : link.title}
              </NavLink>
            )}
          </motion.div>
        );
      })}
    </motion.aside>
  );
};

export default function SubMenu({ logOut }) {
  const { isAuthenticated, isAdmin } = useAuthContext();
  const { width } = useWindowDimensions();
  const [menuType, setMenuType] = useState(null);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const handleSelectMenuType = (selectedType) => {
    if (
      (selectedType === menuType && isVisible) ||
      (menuType === null && isVisible) ||
      selectedType === null
    ) {
      setIsVisible(false);
      return;
    }

    if (width <= 900) {
      setMenuType(null);
      setIsVisible(true);
    } else {
      setMenuType(selectedType);
      setIsVisible(true);
    }
  };

  const filterLinksByType = (type) => {
    if (type === null) return links;

    const filtered = links?.filter((link) => link.type === type);
    return filtered;
  };

  useEffect(() => {
    if (width > 900 && menuType !== null) {
      setFilteredLinks(filterLinksByType(menuType));
    }

    if (width > 900 && menuType === null) {
      setIsVisible(false);
    }

    if (width <= 900) {
      setFilteredLinks(links);
    }
  }, [menuType, width]);

  if (isAuthenticated) {
    return (
      <>
        <div className="header__submenu">
          <button
            type="button"
            className={`header__submenu-item ${
              menuType === "profile" &&
              isVisible &&
              "header__submenu-item--selected"
            }`}
            onClick={() => handleSelectMenuType("profile")}
          >
            <img
              className="header__submenu-item-icon"
              src={relayzGrayIcon}
              alt="Relayz logo"
            />
            <span>Profile</span>
          </button>
          <button
            type="button"
            rel="noopener noreferrer"
            className={`home-description-cta-button-navi header__submenu-item-last ${
              menuType === "meet" &&
              isVisible &&
              "header__submenu-item-last--selected"
            }`}
            // onClick={() => handleSelectMenuType("meet")}
            onClick={() => (window.location.href = "https://meet.relayz.io")}
          >
            Meet Now
          </button>
        </div>
        <AnimatePresence>
          {isVisible && (
            <>
              <div
                className="header__submenu-overlay"
                onClick={() => handleSelectMenuType(null)}
              />
              <MenuItems
                links={filteredLinks}
                showMenu={setIsVisible}
                logOut={logOut}
                isAdmin={isAdmin}
              />
            </>
          )}
        </AnimatePresence>
      </>
    );
  }

  return (
    <div className="header__submenu header__submenu--noauthenticated">
      <NavLink
        to="/nodemanagement"
        rel="noopener noreferrer"
        className={`home-description-cta-button-navi header-nav-link-align-right`}
      >
        Log In
      </NavLink>
      <a
        href="https://meet.relayz.io"

        rel="noopener noreferrer"
        className={`home-description-cta-button-navi header-nav-link-align-right`}
      >
        Meet Now
      </a>
    </div>
  );
}
