import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function App(props) {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo(window.scrollX, element.offsetTop - 100);
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return <div className="App">{props.children}</div>;
}
