// External libraries and styles
import React from "react";
import { motion } from "framer-motion";

import { fadeInAnimation } from "../../functions/animations";
import metamaskLogo from "../../assets/images/web3/metamask-logo.png";
import nearLogo from "../../assets/images/web3/near-logo.png";
import securityIcon from "../../assets/images/web3/security.png";
import web3Icon from "../../assets/images/web3/web3.png";
import "./index.sass";

import { useAuthContext } from "../../contexts/AuthContext";

export default function WalletConnectionSection() {
  const { metamaskLogin, nearLogin } = useAuthContext();

  // Handlers for Near and Metamask logins
  const handleNearLogin = async () => {
    try {
      await nearLogin();
      // Further logic here, if necessary
    } catch (error) {
      console.error("Near login failed:", error);
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      await metamaskLogin();
      //navigate("/nodemanagement/userprofile");
    } catch (error) {
      console.error("Metamask login failed:", error);
    }
  };

  // Helper function to render login buttons
  const renderButton = (onClick, src, alt) => (
    <button className="image-button wallet_connection-item-button" onClick={onClick}>
      <img src={src} alt={alt} />
    </button>
  );

  return (
    <motion.section
      className="wallet_connection"
      initial="hidden"
      animate="visible"
      variants={fadeInAnimation}
    >
      <div className="wallet_connection-wrapper">
        <div className="wallet_connection-item">
          <h2 className="wallet_connection-item-title">
            Authenticate with your wallet to access private and secure{" "}
            <span className="purple-text">Relayz </span> areas.
            <br />
          </h2>
          {renderButton(handleMetamaskLogin, metamaskLogo, "metamask")}
          {renderButton(handleNearLogin, nearLogo, "near")}

          <h4 className="wallet_connection-item-title-login">
            Click on the wallet logo to Login
          </h4>
        </div>
        <div className="wallet_connection-item">
          <h2 className="wallet_connection-item-title">
            Why do I need to connect my wallet?
          </h2>
          <div className="wallet_connection-tutorial-text">
            <ul className="wallet_connection-list">
              <li className="wallet_connection-list-item">
                <span className="wallet_connection-list-item-text">
                  To enable Web3 functionality such as passwordless login,
                  end-to-end encryption, etc.
                </span>
                <img
                  src={web3Icon}
                  alt="web3"
                  className="wallet_connection-list-item-image"
                />
              </li>
              <li className="wallet_connection-list-item">
                <span className="wallet_connection-list-item-text">
                  For security to make sure that no one can impersonate you.
                </span>
                <img
                  src={securityIcon}
                  alt="security"
                  className="wallet_connection-list-item-image"
                />
              </li>
            </ul>
          </div>
          <p className="wallet_connection-tutorial-text">
            <b>
              Please note: Relayz will NOT initiate any transactions of your
              assets.
            </b>
          </p>
        </div>
      </div>
      <h3 className="wallet_connection-bottom-text">
        Your amazing journey starts now.
      </h3>
    </motion.section>
  );
}
