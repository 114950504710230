// External imports
import { useState } from "react";
import { motion } from "framer-motion";
import { useParams, useNavigate } from "react-router-dom";

// Styles
import "./index.sass";

// Assets
import nearLogo from "../../assets/images/web3/near-logo.png";

import metamaskLogo from "../../assets/images/web3/metamask-logo.png";


// Components and utilities
import Footer from "../../components/Footer";
import PageContainer from "../../components/pageContainer";
import RewardCard from "../../components/rewardCard";
import { fadeUpAnimation } from "../../functions/animations";
import StoreContext from "../../store/RootStore";
import { useAuthContext } from "../../contexts/AuthContext";

export default function Welcome() {
  // Context and React Router hooks
  const { FunctionStore } = StoreContext();
  const { referralId } = useParams();

  if (referralId) {
    localStorage.setItem("referralId", referralId);
  }
  const storedReferralId = localStorage.getItem("referralId");

  const {
    isAuthenticated,
    authenticatedUserData: userData,
    metamaskLogin,
  } = useAuthContext();
  const navigate = useNavigate();
  // const { nearLogin } = useAuthContext();

  // UI State management

  const [isNearButtonActive] = useState(true);
  const [isMetaButtonActive] = useState(true);




  const [errorMessage, setErrorMessage] = useState("");


  // Handle Near Login
  const handleNearLogin = async () => {


    try {
      await FunctionStore.loginWithNear();
    } catch (error) {
      console.error("Metamask login failed:", error);
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      await metamaskLogin();
    } catch (error) {
      console.error("Metamask login failed:", error);
    }
  };

  // Retrieve the referral code
  const referralCode = isAuthenticated ? userData.address : undefined;

  // Function to copy the referral code to clipboard
  const handleCopyReferralCode = () => {
    const baseUrl = "https://relayz.io/welcome/";
    navigator.clipboard
      .writeText(baseUrl + referralCode)
      .then(() => {
        alert("Referral link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy referral link: ", err);
        alert("Failed to copy referral link. Please try again.");
      });
  };


  // Render function for wallet login button
  const renderButton = (onClick, src, alt, active) => (
    <button
      className="image-button wallet_connection-item-button"
      onClick={onClick}
      disabled={!active}
    >
      <img src={src} alt={alt} />
    </button>
  );

  const handleFormSubmit = async () => {
    handleCopyReferralCode();
    setErrorMessage("");

    const commonData = {
      name: "",
      referredBy: storedReferralId || "",
      loginType: userData?.loginType,
    };

    let url;
    let data;

    if (userData?.loginType === "near") {
      url = "https://api.relayz.io/api/auth/referral/near/login";
      data = {
        ...commonData,
        nearWalletId: userData?.address,
        publicKey: userData?.publicKey,
      };
    } else if (userData?.loginType === "metamask") {
      // Send POST to /api/user first when loginType is metamask
      const userResponse = await fetch('https://api.relayz.io/api/user', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accountId: userData?.address }),
      });

      if (!userResponse.ok) {
        throw new Error(`HTTP error! Status: ${userResponse.status}`);
      }

      url = "https://api.relayz.io/api/auth/referral/metamask/login";
      data = {
        ...commonData,
        metamaskAddress: userData?.address,
      };
    } else {
      console.error("Unsupported loginType");
      setErrorMessage("There was an issue with the request due to unsupported login type.");
      return;
    }

    if (
      (data.loginType === "near" && (!data.nearWalletId || !data.publicKey)) ||
      (data.loginType === "metamask" && !data.metamaskAddress)
    ) {
      console.error("There was an issue with the request data.");
      setErrorMessage("There was an issue with the request data.");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      localStorage.removeItem("referralId");
      // eslint-disable-next-line 
      const result = await response.json();

      navigate("/nodemanagement");
    } catch (error) {
      console.error("There was an issue with the POST request:", error);
      setErrorMessage("There was an issue with the request.");
    }
  };




  return (
    <PageContainer>

      <motion.section
        className="welcome"
        initial="hidden"
        animate="visible"
        variants={fadeUpAnimation}
      >

        <div className="welcome__container">
          <h1 className="purple-text welcome__title">
            Welcome to The Relayz Reward Program!
          </h1>
          {referralId && (
            <h2 className="welcome__subtitle">
              You have been invited by <span className="purple-text">{referralId}</span>
              <br /> 
              <br /> Please follow the instructions to complete your
              registration.
            </h2>
          )}
          <div className="welcome__section">
            <div className="welcome__section-steps">
              <motion.div className="welcome__section-item">
                <h2 className="welcome__section-title">
                  First sign in with your wallet
                </h2>
                <div className="near-section">
                  {renderButton(
                    handleNearLogin,
                    nearLogo,
                    "near",
                    isNearButtonActive
                  )}
                  {renderButton(
                    handleMetamaskLogin,
                    metamaskLogo,
                    "metamask",
                    isMetaButtonActive
                  )}

                  {isAuthenticated ? (
                    <div id="nearSucess" className="near-section-success">
                      <p>Wallet logged in successfully</p>
                    </div>
                  ) : (
                    <div id="nearFail" className="near-section-success">
                      <p>Click on the wallet logo to join</p>
                    </div>
                  )}
                </div>
                <small className="welcome__section-error-log">
                  {errorMessage}
                </small>


                <div className="welcome__section-steps-buttons">
                  <button
                    className=" welcome__section-steps-next"
                    onClick={handleFormSubmit}

                  >
                    Submit
                  </button>
                </div>
              </motion.div>
            </div>
            <div className="welcome__section-item">



              <RewardCard />



            </div>

          </div>
        </div>

        <Footer />
      </motion.section>
    </PageContainer>
  );
}
