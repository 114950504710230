export function formatNumberFractionDigits(number, fractionDigits) {
  return number.toLocaleString("de-DE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}
//proper number formatting
export function formatNumber(number) {
  let numberInMB = number/1024;
  if (numberInMB < 1000) {
    return `${Math.round(numberInMB)} MB`;
  } else if (numberInMB < 1000000) {
    return `${formatNumberFractionDigits(numberInMB / 1024, 2)} GB`;
  } else {
    return `${formatNumberFractionDigits(numberInMB / 1024 / 1024, 2)} TB`;
  }
}
export function breakOwnerIdWord(ownerId) {
  const [firstWord, secondWord] = ownerId.split(".");

  if(!secondWord) {    
    return {
      firstWord: firstWord,
      secondWord: "",
    };
  }
  
  return {
    firstWord: firstWord + ".",
    secondWord
  };
}

export function breakWalletName(walletName) {
  const [firstWord, secondWord] = walletName.split(".");

  if (walletName.length > 25) {
    const firstPart = walletName.slice(0, 5);
    const lastSixCharacters = walletName.slice(-7);
    return {
      firstWord: firstPart + "..." + lastSixCharacters,
      secondWord: "",
    };
  }

  if (!secondWord) {    
    return {
      firstWord: firstWord,
      secondWord: "",
    };
  }
  
  return {
    firstWord: firstWord + ".",
    secondWord
  };
}

export function parseDateISOtoDefault(dataISO) {
  const data = new Date(dataISO); 
  const dia = data.getDate().toString().padStart(2, '0'); 
  const mes = (data.getMonth() + 1).toString().padStart(2, '0'); 
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export function findMostRecentInviteDate(invitesList) {
  if (!Array.isArray(invitesList) || invitesList.length === 0) {
    return null; // Return null for empty or invalid input
  }

  let mostRecentDate = new Date(invitesList[0].createdAt);
  let mostRecentDateItem = invitesList[0];

  for (let i = 1; i < invitesList.length; i++) {
    const currentDate = new Date(invitesList[i].createdAt);

    if (currentDate > mostRecentDate && invitesList[i].status === "complete") {
      mostRecentDate = currentDate;
      mostRecentDateItem = invitesList[i];
    }
  }

  return mostRecentDateItem;
}

export const truncateOwnerId = (ownerId) => {
  if (ownerId.length > 25) {
    return ownerId.slice(0, 10) + "..." + ownerId.slice(-10);
  }
  return ownerId;
};