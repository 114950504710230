import { NavLink } from "react-router-dom";
//import blobBackground from "../../assets/images/blob3.svg";
//import rewardProgramImg from "../../assets/images/reward-program.png";
import rewardProgramImg from "../../assets/images/site-home6.png";

import "./index.sass";

export default function CtaGalRewards() {
  return (

      <section className="home__section-wrapper">
      <div className="home-section home-description">
        <p className="cta-reward-program__content-title">Join Our Community <br /> Rewards Awaits You!</p>
        <div className="cta-reward-program__content-images">
           {/* <img className="cta-reward-program__background-image" src={blobBackground} alt="Blob background" /> */}
         
          <img className="cta-reward-program__hero-image" src={rewardProgramImg} alt="Relayz reward program" />
        </div>
        
        <div className="home-section-content cta-reward-program__content-info">
          <p className="cta-reward-program__content-text1-1 large-title purple-text">Join Our Community <br /> Rewards Awaits You!</p>
          <p className="cta-reward-program__content-text4 subHeader__font">The rewards are powered by Galxe, complete the required tasks to:</p>
          <p className="cta-reward-program__content-text3 subHeader__font">
            <ul className="cta-reward-program__content-liImage">
              <li>Qualify for the rewards</li>
              <li>Gain whitelist priority</li>
              <li>Participate in future activities</li>
            </ul>
          </p>
          <NavLink
            to="https://galxe.com/relayz/campaign/GCHZ2twKfB"
            className="cta-reward-program__content-link"
            target="_blank"
          >
            Join Now
          </NavLink>
        </div>
      </div>
    </section>
  );
}