const NEWS_API_URL = 'https://api.relayz.io/api/news';
//const NEWS_API_URL = 'http://localhost:3000/api/news';

export const fetchNews = async () => {
  try {
    const response = await fetch(NEWS_API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const json = await response.json();
    return json.data || [];
  } catch (error) {
    console.error('Error fetching news:', error);
    throw error; // Re-throw the error to be handled by the calling component
  }
};

export const postNews = async (newsItem) => {
  try {
    const response = await fetch(NEWS_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include other headers like authorization if needed
      },
      body: JSON.stringify(newsItem),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error posting news:', error);
    throw error; // Re-throw the error to be handled by the calling component
  }
};

// export const deleteNews = async (newsId) => {
//   try {
//     const response = await fetch(NEWS_API_URL, {
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json',
//         // Include other headers like authorization if needed
//       },
//       body: JSON.stringify({ id: newsId }),
//     });
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('Error deleting news:', error);
//     throw error;
//   }
// };


