export const blockAnimation = {
    hidden: {
        y: -60,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1
    }
}

export const blockAnimationFade = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1
    },
    changeColor: {
        color: "#66458E"
    }
}

export const fadeUpAnimation = {
    hidden: {
        y: 60,
        opacity: 0
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: { delay: custom * 0.2 }
    })
}

// Created new animation for transitions between components
export const fadeInAnimation = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 1
        }
    },
}

export const textAnimation = {
    hidden: {
        x: -60,
        opacity: 0
    },
    visible: custom => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * 0.2 }
    })
}

export const gradientAnimation = {
    hidden: {
        background: "#000"
    },
    visible: {
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(102,69,142,1) 70%)",
    }
}

export const burgerItemVariants = {
    closed: {
        opacity: 0
    },
    open: { opacity: 1 }
};

export const burgerSideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    open: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
};

export const textAnimationRight = {
    hidden: {
        x: 100,
        opacity: 0
    },
    visible: custom => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * 0.2 }
    })
}


export const subMenuAnimation = {
    initial: { height: 0, opacity: 0 },
    animate: { height: "auto", opacity: 1, transition: { height: { duration: 0.2 }, opacity: { duration: 0.2 } } },
    exit: { height: 0, opacity: 0, transition: { height: { duration: 0.2 }, opacity: { duration: 0.5 } } },
};

export const subMenuItemAnimation = {
    initial: { opacity: 0 },
    exit: { opacity: 0, transition: { opacity: { duration: 0.1 } } }
};