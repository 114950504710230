import "./index.sass";
//import { motion } from "framer-motion";
import { fadeUpAnimation } from "../../functions/animations";
import Footer from "../../components/Footer";
import PageContainer from "../../components/pageContainer";
import roadMapImage from "../../assets/images/roadmap001.png";
import roadMapImageIcon02 from "../../assets/images/timeline2021.png";
import roadMapImageIcon04 from "../../assets/images/timeline2023.png";
import roadMapImageIcon05 from "../../assets/images/timeline2024.png";
import roadMapImageIcon06 from "../../assets/images/timeline2025.png";

import ParallaxPartners from "../../components/parallaxPartners";


export default function About() {


  return (
    <PageContainer>
      <section className="section__wrapper">
        <div
          variants={fadeUpAnimation}
          custom={6}
          className="purple-text header__font"
        >
          Introduction
        </div>
        <div
          initial="hidden"
          // whileInView="visible"
          id="introduction"
        >
          <div
            variants={fadeUpAnimation}
            custom={1}
            className="about-subtext subHeader__font"
          >
            Relayz is built by a team of cybersecurity and blockchain geeks who
            endeavor to create a game-changing protocol that is truly
            decentralized, secure, private, censorship-proof and revolutionary. We
            will disrupt the old way of Web2 online communications.
          </div>
        </div>
        
      </section>
      <section className="roadmap__section">
        <h4
          style={{ marginTop: "-0.1rem" }}
          className="purple-text header__font"
        >
          Roadmap
        </h4>
        <div className="roadmap__container">
          <div>
            <img
              className="roadmap__graph"
              src={roadMapImage}
              alt="image1"
              style={{ width: "80vw", maxWidth: "1000px", marginBottom: "auto" }}
            ></img>
          </div>
        </div>
        <div className="roadmap__container">
          <article className="roadmap__subtitle">
            <div className="roadmap__time2022">
              <img
                src={roadMapImageIcon02}
                alt="image2"
                style={{
                  objectFit: "contain",
                  margin: "2rem 1rem 1rem 0",
                  width: "10vw",
                  maxWidth: "8rem",
                  objectPosition: "left",
                  height: "45px",
                }}
              ></img>
              <div className="roadmap__subtitle--item--container">
                <h3
                  className="subHeader__font purple-text"
                  style={{ marginBottom: "1rem" }}
                >
                  2020 - 2022
                </h3>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  MVP Dev
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  MVP Testing
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Setup Infrastructure
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Website
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Smart contracts dev
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet Alpha 1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  UI integration
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet Alpha 2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet Beta 1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Testnet Alpha 
                </p>
              </div>
            </div>

            <div className="roadmap__flexo">
              <img
                src={roadMapImageIcon06}
                alt="image3"
                style={{
                  objectFit: "contain",
                  margin: "2rem 1rem 1rem 0",
                  width: "10vw",
                  maxWidth: "8rem",
                  objectPosition: "left",
                  height: "45px",
                }}
              ></img>
              <div className="roadmap__subtitle--item--container">
                <h3
                  className="subHeader__font purple-text"
                  style={{ marginBottom: "1rem", }}
                >
                  2023
                </h3>
                <p className="paragraphs" style={{ marginBottom: "1rem" }}>
                  H1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet Beta 2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Testnet Beta 1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API Alfa
                </p>

                <p className="paragraphs" style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  {" "}
                  H2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet RC1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Testnet Beta 2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API Beta 
                </p>
              </div>
            </div>

            <div className="roadmap__flexo">
              <img
                src={roadMapImageIcon05}
                alt="image4"
                style={{
                  objectFit: "contain",
                  margin: "2rem 1rem 1rem 0",
                  width: "10vw",
                  maxWidth: "8rem",
                  objectPosition: "left",
                  height: "45px",
                }}
              ></img>
              <div className="roadmap__subtitle--item--container">
                <h3
                  className="subHeader__font purple-text"
                  style={{ marginBottom: "1rem" }}
                >
                  2024
                </h3>
                <p className="paragraphs" style={{ marginBottom: "1rem" }}>
                  H1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet v1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Subscriptions Dev
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Chat Alpha 
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Mainnet v1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Multichain 1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API v1
                </p>

                <p className="paragraphs" style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  {" "}
                  H2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Pay-as-you-go v1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Subscriptions v1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet v2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Chat Beta 
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Mainnet v2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Multichain 2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API v2
                </p>
              </div>
            </div>

            <div className="roadmap__flexo">
              <img
                src={roadMapImageIcon04}
                alt="image5"
                style={{
                  objectFit: "contain",
                  margin: "2rem 1rem 1rem 0",
                  width: "10vw",
                  maxWidth: "8rem",
                  objectPosition: "left",
                  height: "45px",
                }}
              ></img>
              <div className="roadmap__subtitle--item--container">
                <h3
                  className="subHeader__font purple-text"
                  style={{ marginBottom: "1rem" }}
                >
                  2025
                </h3>
                <p className="paragraphs" style={{ marginBottom: "1rem" }}>
                  H1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Chat v1
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet v3
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Collab App Alpha
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Metaverse/SDK Alpha
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Mainnet v3
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Multichain 3
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API v3
                </p>

                <p className="paragraphs" style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  {" "}
                  H2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Chat v2
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Meet v4
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Collab App Beta
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Metaverse/SDK Beta
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Mainnet v4
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  Multichain 4
                </p>
                <p className="paragraphs" style={{ marginBottom: "0.25rem" }}>
                  API v4
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section className="section__wrapper">
        {/* Partners infinite slider */}
        <ParallaxPartners />
      </section>
      <Footer />
    </PageContainer>

  );
}
