import { useState } from "react";
import { breakWalletName, parseDateISOtoDefault } from "../../functions/utils";

export default function InvitationsList({ list, onInviteSelected }) {
  const [selectedInviteId, setSelectedInviteId] = useState(null);

  const handleSelectInvite = (invite) => {
      if (selectedInviteId !== invite.id) {
          setSelectedInviteId(invite.id);
          onInviteSelected(invite.id); // inform the parent about the selection
      } else {
          setSelectedInviteId(null);
          onInviteSelected(null); // inform the parent that no invite is selected now
      }
  }

  const renderTableHeader = () => ( 
    <div className="node_table-header">
      <div className="node_table-header-unit">Referred Wallet</div>
      <div
        className={`node_table-header-unit node_table-header--no-mobile`}
      >
        Level
      </div>
      <div
        className={`node_table-header-unit`}
      >
        Date
      </div>
      <div className="node_table-header-unit node_table-header--no-small-mobile">
        Registration
      </div>
    </div>
  );

  return (
    <>
      <h4 className="node_table-title">My Referrals</h4> 
      {list && list.length > 0 ? (
        <>        
          {renderTableHeader()}
          {
            list.map((invite) => {
              const owner = breakWalletName(invite.referredWallet);
              return (
                <div key={invite.id} className="node_item">
                  <div                    
                    className={`
                      ${
                        selectedInviteId === invite.id
                          ? "node_item-container node_item-container--selected"
                          : "node_item-container"
                      }
                    `}
                    onClick={() => handleSelectInvite(invite)}
                  >
                    <div className="node_item-row-unit">
                      <span className="node_item-row-unit-ownerId">
                        {`${owner.firstWord}`}
                      </span>
                      <span className="node_item-row-unit-ownerId">
                        {`${owner.secondWord}`}
                      </span>
                    </div>
                    <div className="node_item-row-unit node_item-row-container--no-mobile">
                      <span>
                        { invite.level ? invite.level : "1" }
                      </span>
                    </div>
                    <div className="node_item-row-unit">
                      <span>
                        { parseDateISOtoDefault(invite.createdAt) }
                      </span>
                    </div>
                    <div className="node_item-row-unit node_item-row-container--no-small-mobile capitalize-text">
                      <span>
                        { invite.status }
                      </span>
                    </div>
                  </div>             
                </div>
              )
            })
          }
        </>
      )
      :
        <>
          <p>No invitation sent yet.</p>
        </>    
      } 
    </>
  );
}