import "./index.sass"

// data imports
// import { fetchNews, postNews, deleteNews } from '../../texts/news'; // Correct the path if necessary
import { fetchNews, postNews } from '../../texts/news'; // Correct the path if necessary

// external imports
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// authentication imports
import { useAuthContext } from "../../contexts/AuthContext";

// local imports
import Footer from "../../components/Footer";






export default function NewsManagement() {
    const { isAdmin } = useAuthContext();
    const navigate = useNavigate();
    const alertShown = useRef(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date().toISOString().split("T")[0]);
    const [filteredNewsData, setFilteredNewsData] = useState([]);
    const [futureNewsData, setFutureNewsData] = useState([]);

    // const [newsId, setNewsId] = useState('');
    // const handleIdChange = (event) => setNewsId(event.target.value);


    useEffect(() => {
        if (!isAdmin && !alertShown.current) {
            alert("You do not have access to this page.");
            alertShown.current = true;
            navigate('/');
        } else {
            fetchNewsData();
        }
    }, [isAdmin, navigate]);

    const fetchNewsData = async () => {
        try {
            const newsItems = await fetchNews();
            const now = new Date(); // Use the current date and time for comparison
    
            const currentData = newsItems.filter((data) => {
                const dataDate = new Date(data.createdAt);
                // Exclude news with 'Default News Title' and include news up to the current moment
                return data.title !== 'Default News Title' && dataDate <= now;
            });
    
            const futureData = newsItems.filter((data) => {
                const dataDate = new Date(data.createdAt);
                // Exclude news with 'Default News Title' and only include future news items
                return data.title !== 'Default News Title' && dataDate > now;
            });
    
            setFilteredNewsData(currentData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            setFutureNewsData(futureData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
        } catch (error) {
            alert('An error occurred while fetching the news. Please try again.');
        }
    };
    




    const handleTitleChange = (event) => setTitle(event.target.value);
    const handleBodyChange = (event) => setBody(event.target.value);
    const handleDateChange = (event) => setCreatedAt(event.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newEntry = {
            title,
            body: body.split('\n').map(line => `<p>${line}</p>`).join(''),
            createdAt: createdAt || new Date().toISOString()
        };

        try {
            const result = await postNews(newEntry);
            // Handle the response after posting the news
            // You may want to fetch the news data again to update the list
            fetchNewsData();
            alert(`News posted successfully: ${result.message}`);
        } catch (error) {
            alert('An error occurred while posting the news. Please try again.');
        }

        // Determine if the entry is for the future and sort it into the correct array
        const entryDate = new Date(newEntry.createdAt);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Ensure we are comparing just dates, not times
        let alertMessage = ''; // Initialize alert message

        if (entryDate > today) {
            setFutureNewsData([...futureNewsData, newEntry].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
            alertMessage = `The news "${title}" has been scheduled for ${createdAt}.`;
        } else {
            setFilteredNewsData([...filteredNewsData, newEntry].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            alertMessage = `Your news "${title}" has been successfully posted with the date ${createdAt}.`;
        }

        setTitle('');
        setBody('');
        setCreatedAt(new Date().toISOString().split("T")[0]);

        alert(alertMessage);
        document.getElementById(entryDate > today ? 'future-news' : 'news').scrollIntoView({ behavior: 'smooth' });
    };

    // const handleDelete = async (newsId) => {
    //     if (window.confirm('Are you sure you want to delete this news item?')) {
    //         try {
    //             await deleteNews(newsId);
    //             alert('News deleted successfully');
    //             fetchNewsData(); // refresh the news list after deletion
    //         } catch (error) {
    //             alert('An error occurred while deleting the news. Please try again.');
    //         }
    //     }
    // };



    return (
        <div className="news__page">

            <section>
                <div className="news__form-title">
                    <h2 className="header__font--left purple-text">Add News</h2>
                </div>
                <div className="news__form">

                    <form className="news__form-wrapper" onSubmit={handleSubmit} >
                        <label className="news__form-item">
                            Title:
                            <input type="text" value={title} onChange={handleTitleChange} required />
                        </label>
                        <label className="news__form-item">
                            Created At:
                            <input type="date" value={createdAt} onChange={handleDateChange} />
                        </label>
                        <label className="news__form-item">
                            Body (HTML supported):
                            <textarea value={body} onChange={handleBodyChange} required />
                        </label>
                        <div className="form__button-wrapper">
                            <button className="home-description-cta-button" type="submit">Submit</button>
                        </div>
                    </form>

                    {/* <div className="news__form-wrapper">
                        <label className="news__form-item">
                            ID:
                            <input type="text" value={newsId} onChange={handleIdChange} required />
                        </label>
                        <div className="form__button-wrapper">
                        
                            <button
                                className="home-description-cta-button"
                                type="button"
                                onClick={() => handleDelete(newsId)} 
                            >
                                Delete
                            </button>
                        </div>
                    </div> */}
                </div>
            </section>
            <section>
                <div className="news__form-title">
                    <h2 id="news" className="header__font--left purple-text">News</h2>
                </div>
                <div className="home-section-adverts--news">
                    <div className="news__form">
                        <div className="home-section-adverts--message-container">
                            {filteredNewsData.length > 0 ? (
                                filteredNewsData.map((data) => (
                                    <div className="home-section-adverts--message" key={data.createdAt}>
                                        <h4>{data.title}</h4>
                                        <span className="home-section-adverts--date">
                                            {new Date(data.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric', month: 'long', day: 'numeric',
                                                hour: '2-digit', minute: '2-digit', second: '2-digit',
                                                hour12: true,
                                                timeZoneName: 'short'
                                            })}
                                        </span>
                                        <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                    </div>
                                ))
                            ) : (
                                <p className="home-section-adverts--message">No current news to show.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* Future News Section */}
            <section id="future-news">
                <div className="news__form-title">
                    <h2 className="header__font--left purple-text">Future News</h2>
                </div>
                <div className="home-section-adverts--news">
                    <div className="news__form">
                        <div className="home-section-adverts--message-container">
                            {futureNewsData.length > 0 ? (
                                futureNewsData.map((data) => (
                                    <div className="home-section-adverts--message" key={data.createdAt}>
                                        <h4>{data.title}</h4>
                                        <span className="home-section-adverts--date">
                                            {new Date(data.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric', month: 'long', day: 'numeric',
                                                hour: '2-digit', minute: '2-digit', second: '2-digit',
                                                hour12: true,
                                                timeZoneName: 'short'
                                            })}
                                        </span>
                                        <div dangerouslySetInnerHTML={{ __html: data.body }} />
                                    </div>
                                ))
                            ) : (
                                <p className="home-section-adverts--message">No news scheduled to be posted in future dates.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />

            <Footer />

        </div>

    );
}