import { useMetaMask } from "metamask-react";
import StoreContext from "../store/RootStore";
const process = require('process');

export default function useLoginWithMetamask() {
  const { FunctionStore, AuthStore } = StoreContext();
  const { connect, account, status, chainId, addChain } = useMetaMask();

  const checkMetamaskLogin = async () => {
    // console.log("Checking Metamask login...");
    if (account) {
      // console.log("Metamask account: ", account);
      AuthStore.setUserData("metamask", account, null);
    } else {
      // console.log("Metamask not authenticated");
    }
  };

  const loginWithMetamask = async () => {
    try {
      const result = await connect();
      if (typeof result[0] === "string") {
        if (chainId !== "1313161554") {
          const auroraChainNetworkParams = {
            chainId: "0x4e454153",
            chainName: "Aurora Testnet",
            rpcUrls: [process.env.ETH_RCP_URL || 'https://testnet.aurora.dev/'],
            nativeCurrency: {
              name: "ETH",
              symbol: "ETH",
              decimals: 18,
            },
            blockExplorerUrls: [process.env.ETH_BLOCK_EXPLORE_URL || 'https://explorer.testnet.aurora.dev/']
          };
          await addChain(auroraChainNetworkParams);
        } 
        // Você já realizou ações após o login bem-sucedido, agora pode chamar checkMetamaskLogin diretamente aqui.     
        // console.log("metawallet", account);
        AuthStore.setUserData("metamask", result[0], null);
        await checkMetamaskLogin();
      } else {
        FunctionStore.handleError(`An error occurred while connecting to metamask. Status: ${status}`);
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };

  return {
    loginWithMetamask,
    checkMetamaskLogin
  };
}
