/* A helper file that simplifies using the wallet selector */

// Imports for near-api-js
import { providers } from 'near-api-js';

// Imports for wallet selector UI
import '@near-wallet-selector/modal-ui/styles.css';
import { setupModal } from '@near-wallet-selector/modal-ui';

// Imports for wallet selector options
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupLedger } from '@near-wallet-selector/ledger';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import ledgerIcon from '../assets/images/ledger-icon.png';
import nearIcon from '../assets/images/my-near-wallet-icon.png';

// Constants
const process = require('process');
const THIRTY_TGAS = process.env.NEAR_THIRTY_TGAS || '30000000000000';
const NO_DEPOSIT = process.env.NEAR_NO_DEPOSIT || '0';

/**
 * Wallet class that simplifies the usage of the wallet selector.
 */
export class Wallet {
    constructor({ createAccessKeyFor = undefined, network = (process.env.NEAR_NETWORK || 'testnet') }) {
        this.createAccessKeyFor = createAccessKeyFor;
        this.network = network;
    }

    // Initialization method called when the website loads
    async startUp() {
        this.walletSelector = await setupWalletSelector({
            network: this.network,
            modules: [setupMyNearWallet({ nearIcon }), setupLedger({ ledgerIcon })],
        });

        if (this.walletSelector.isSignedIn()) {
            this.wallet = await this.walletSelector.wallet();
            const state = this.walletSelector.store.getState();
            this.accountId = state.accounts[0].accountId;
            this.publicKey = state.accounts[0].publicKey;

            return { accountId: this.accountId, publicKey: this.publicKey };
        }

        return false; // Explicitly returning false if not signed in
    }

    // User sign-in method
    signIn() {
        const description = 'Please select a wallet to sign in.';
        const modal = setupModal(this.walletSelector, { contractId: this.createAccessKeyFor, description });
        modal.show();
    }

    // User sign-out method
    signOut() {
        this.wallet.signOut();
        this.wallet = this.accountId = this.createAccessKeyFor = null;
        window.location.replace(window.location.origin + window.location.pathname);
    }

    // Retrieve information from the network without changing any state
    async viewMethod({ contractId, method, args = {} }) {
        const provider = new providers.JsonRpcProvider({ url: this.walletSelector.options.network.nodeUrl });

        const response = await provider.query({
            request_type: 'call_function',
            account_id: contractId,
            method_name: method,
            args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
            finality: 'optimistic',
        });

        return JSON.parse(Buffer.from(response.result).toString());
    }

    // Call a method that potentially changes the contract's state
    async callMethod({ contractId, method, args = {}, gas = THIRTY_TGAS, deposit = NO_DEPOSIT }) {
        return await this.wallet.signAndSendTransaction({
            signerId: this.accountId,
            receiverId: contractId,
            actions: [{
                type: 'FunctionCall',
                params: { methodName: method, args, gas, deposit },
            }],
        });
    }

    // Retrieve the result of a transaction from the network
    async getTransactionResult(txhash) {
        const provider = new providers.JsonRpcProvider({ url: this.walletSelector.options.network.nodeUrl });
        const transaction = await provider.txStatus(txhash, 'unnused');
        return providers.getTransactionLastResult(transaction);
    }
}
