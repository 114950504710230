import "./index.sass";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import React from "react";
import PageContainer from "../../components/pageContainer";

export default function Network() {
  

  return (
    <PageContainer>
      <div className="body_management">
        <div id="nodeprofile">
          <Outlet />
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}
