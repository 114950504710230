import "./index.sass";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import PageContainer from "../../components/pageContainer";

export default function Network() {
  return (
    <PageContainer>      
      <div id="testnet">
        <Outlet />
      </div>

      <Footer />
    </PageContainer>
  );
}
