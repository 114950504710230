import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useGetSponsors from "../../functions/useGetSponsors";
import "./index.sass";

const ParallaxPartners = () => {
  const sponsors = useGetSponsors();

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    variableWidth: true,
    draggable: false, 
    swipe: false, 
    touchMove: false, 
    prevArrow: false,
    nextArrow: false,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <section className="parallax-partners__section">
      <h2 className="header__font purple-text">Partners</h2>
      <div className="parallax-partners__container">
        <Slider {...settings}>
          {sponsors.map((sponsor) => (
            <a href={sponsor.url} key={sponsor.url} alt="sponsor logo" target='blank'>
              <img
                className="home-benefits-partners-item parallax-partners__image"
                src={sponsor.image}
                alt={`Sponsor ${sponsor.url}`}
                target='blank'
              />
            </a>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ParallaxPartners;