import React from 'react';
import { findMostRecentInviteDate, formatNumber, parseDateISOtoDefault, truncateOwnerId } from '../../functions/utils';

const ManagementCard = ({
    selectedNode,
    selectedTab,
    invitationsData,
    isAdminUser
    // selectedInviteId
}) => {
    const {
        ownerId,
        nodeId,
        ip,
        traffic,
        bandwidth,
        region,
        meetingsProcessed,
    } = selectedNode || {};  // Assuming these fields are within selectedNode, if not you need to pass them as separate props.

    const renderInfo = (label, value) => (
        <div className="management_card-info_item">
            <span className="management_card-info_label">{label}:</span>
            <span className="management_card-info_value">{value}</span>
        </div>
    );

    const lastCompletedReferral = findMostRecentInviteDate(invitationsData);

    // console.log(invitationsData)

    const renderDefaultInfo = () => (
        <div className="management_card-info">
            <div className="management_card-title">
                Selected Node Details
            </div>

            {/* <div className="management_card-subtitle">
                {selectedNode ? selectedNode.ownerId : "No Node Selected"}
            </div> */}
            {renderInfo("Owner ID", ownerId || "-")}
            {
              ip && isAdminUser && renderInfo("IP", ip)
            }
            {renderInfo("Node ID", nodeId && isAdminUser ? nodeId : nodeId ? nodeId.substring(0, 5) : "-")}
            {renderInfo(
                "Traffic",
                traffic === "0"
                    ? "0 MB"
                    : `${traffic ? formatNumber(parseFloat(traffic)) : "-"}`
            )}
            {renderInfo(
                "Download Speed",
                bandwidth?.download?.speed
                    ? `${bandwidth.download.speed} Mbps`
                    : "-"
            )}
            {renderInfo(
                "Upload Speed",
                bandwidth?.upload?.speed
                    ? `${bandwidth.upload.speed} Mbps`
                    : "-"
            )}
            {renderInfo("Region", region || "-")}
            {renderInfo("Meetings Processed", meetingsProcessed || "-")}
        </div>
    );

    const renderRewardProgramInfo = () => {    

        // let selectedInvite;
    
        // if (selectedInviteId) {
        //     selectedInvite = invitationsData.find(invite => invite.id === selectedInviteId);
        // } else if (invitationsData && invitationsData.length > 0) {
        //     selectedInvite = invitationsData[0];  // default to the first invite in the list
        // }

        if (!invitationsData) return null;  // return null if no matching invite is found or list is empty
    
        return (
          <div className="management_card-referrals">
            <h4 className="management_card-referrals-title purple-text">Your total completed referrals</h4>
            <span className="management_card-referrals-total">{invitationsData?.length}</span>
            {invitationsData?.length > 0 &&
              <h4 className="management_card-referrals-title management_card-referrals-title--bottom">Your last completed referral</h4>
            }
            <div className="management_card-referrals-last">
              <span className="management_card-referrals-info">{ lastCompletedReferral?.referredWallet && truncateOwnerId(lastCompletedReferral?.referredWallet) }</span>
              <span className="management_card-referrals-info">{ lastCompletedReferral?.createdAt && parseDateISOtoDefault(lastCompletedReferral?.createdAt) }</span>
            </div>
          </div>
        );
    };
    
    

    return (
        <div>
            {selectedTab === "Reward Program" ? renderRewardProgramInfo() : renderDefaultInfo()}
        </div>
    );
}

export default ManagementCard;
