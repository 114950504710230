import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.sass";

function RewardlCard() {
  const [activeProgram, setActiveProgram] = useState("meet");

  return (
    <div className="relayz-card-wrapper">
      <div className="relayz-card">
        <h2 className="purple-text relayz-card-titles">
          Relayz Invite Rewards System
        </h2>
        <p>Click on the program below to know more about its rewards</p>

        <div className="relayz-card-titles">
          <h4
            className={activeProgram === "meet" ? "active" : ""}
            onClick={() => setActiveProgram("meet")}
          >
            Relayz Meet Invite
          </h4>
          <h4
            className={activeProgram === "node" ? "active" : ""}
            onClick={() => setActiveProgram("node")}
          >
            Relayz Node Invite
          </h4>
        </div>

        {activeProgram === "meet" && (
          <>
            <p className="reward_tab-list-title">
              The referral system rewards you for every referral of an active
              node operator on RelyNet
            </p>

            <p className="reward_tab-list-title">Rewards are calculated by</p>
            <ul className="relayz-card-list">
              <li>
                {" "}
                1) Doing Relayz Meet calls - frequency of the calls you and your
                invited users make, duration of the calls, number of people on
                the calls
              </li>
              <li>
                {" "}
                2) Joining{" "}
                <a
                  target="blank"
                  href="https://twitter.com/RelayzOfficial"
                  className="purple-text"
                >
                  Relayz Twitter
                </a>
              </li>
              <li>
                {" "}
                3) Joining{" "}
                <a
                  target="blank"
                  href="https://discord.com/invite/DuEJEMss9G"
                  className="purple-text"
                >
                  Relayz Discord
                </a>
              </li>
              <li>
                {" "}
                4) Joining{" "}
                <a
                  target="blank"
                  href="https://t.me/RelayzOfficial"
                  className="purple-text"
                >
                  Relayz Telegram
                </a>
              </li>
            </ul>
            <br />

            <p className="relayz-card-description">
              All the above have an impact on the calculation of rewards, the
              more active you are by doing Relayz Meet calls the higher the
              rewards.
            </p>
            <br />
            <p>Joining Relayz socials gives extra rewards.</p>
            <NavLink
              className="welcome__section-title purple-text"
              to="/invite"
            >
              {" "}
              <br />
              Click here to learn more
            </NavLink>
          </>
        )}

        {activeProgram === "node" && (
          <>
            <p className="reward_tab-list-title">
              The referral system rewards you for every referral of an active
              user of Relayz Meet
            </p>
            <p className="reward_tab-list-title">Rewards are calculated by</p>
            <ul className="relayz-card-list">
              <li>
                {" "}
                1) When an invited node operator signs up with your referral
                code and begins running nodes on RelyNet over a minimum period
                of 3 months and up to 6 months, you will receive monthly rewards
              </li>
              <li>
                {" "}
                2) Joining{" "}
                <a
                  target="blank"
                  href="https://twitter.com/RelayzOfficial"
                  className="purple-text"
                >
                  Relayz Twitter
                </a>
              </li>
              <li>
                {" "}
                3) Joining{" "}
                <a
                  target="blank"
                  href="https://discord.com/invite/DuEJEMss9G"
                  className="purple-text"
                >
                  Relayz Discord
                </a>
              </li>
              <li>
                {" "}
                4) Joining{" "}
                <a
                  target="blank"
                  href="https://t.me/RelayzOfficial"
                  className="purple-text"
                >
                  Relayz Telegram
                </a>
              </li>
            </ul>
            <br />

            <p className="relayz-card-description">
              All the above have an impact on the calculation of rewards, the
              more active you are by doing Relayz Meet calls the higher the
              rewards.
            </p>
            <br />
            <p>Joining Relayz socials gives extra rewards.</p>
            <NavLink
              className="welcome__section-title purple-text"
              to="/invite"
            >
              {" "}
              <br />
              Click here to learn more
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}

export default RewardlCard;
