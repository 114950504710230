import "./index.sass";
import { motion } from "framer-motion";

import meetDesktop from "../../assets/images/meet/meet-desktop.png";
import meetTablet from "../../assets/images/meet/meet-tablet.png";
import meetMobile from "../../assets/images/meet/meet-mobile.png";
import meetSample from "../../assets/images/meet/meet-sample.png";


const SectionImage = ({ className, src, alt, x, y }) => {
  return (
    <motion.img className={className}
      whileHover={{ scale: 1.05 }}
      initial={{ x: x, y: y }}
      whileInView={{ x: 0, y: 0, transition: { duration: 1.5 } }}
      viewport={{ once: true }}
      src={src}
      alt={alt}
    />
  )
}

export default function CtaRelayzMeet() {
  return (
    <section className="cta-reward-program__section">
      <div className="cta-reward-program__content-container">
        <div className="home-section-content-black">
          <h2 className="purple-text header__font--left">Relayz Meet</h2>
          <p className="subHeader__font">Privacy-first, highly secure, scalable and decentralized communication infrastructure. </p>

          <div className="home-section-image--sample-mobile">
            <img src={meetSample} alt="Relayz meet application screen" />
          </div>


          <h4 className="purple-text subHeader__font">HD Quality video calls</h4>
          <p className="subHeader__font">Excellent High Definition video-conferencing accessible with a browser on any device, anywhere.</p>
          <h4 className="purple-text subHeader__font">Privacy Focused</h4>
          <p className="subHeader__font">
            Immerse yourself with unshakable trust in your conversations.
          </p>
        </div>
        <div className="home-section-image--sample">
          <img src={meetSample} alt="Relayz meet application screen" />
        </div>
        <div className="home-section-images">
          <SectionImage
            className="home-section-image home-section-image--mobile"
            src={meetMobile}
            alt="Relayz meet application mobile screen"
            x="10vw"
            y="5vw"
          />
          <SectionImage
            className="home-section-image home-section-image--tablet"
            src={meetTablet}
            alt="Relayz meet application tablet screen"
            x="5vw"
            y="-33vw"
          />
          <SectionImage
            className="home-section-image home-section-image--desktop"
            src={meetDesktop}
            alt="Relayz meet application desktop screen"
            x="-5vw"
            y="5vw"
          />
        </div>
      </div>
    </section>
  );
}