import { NavLink } from "react-router-dom";
import "./index.sass";
import Bowser from "bowser";

export default function Subheader({ links, activeLink = "" }) {

  const isChrome = Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Chrome';

  const getNavLinkClassName = (link) => {
    const isActive = activeLink.toLowerCase() === `/${link.toLowerCase()}`;
    return `subheader-link${isActive ? " subheader-link-active" : ""}`;
  };

  return (
    <div className={`subheader ${isChrome ? 'subheader-solid' : ''}`}>
      {links.map((link, index) => (
        <NavLink
          className={getNavLinkClassName(link)}
          key={`${index}-${link}`}  // It's better to add a hyphen for readability
          to={`/${activeLink}/${link.toLowerCase()}`}
        >
          {link}
        </NavLink>
      ))}
    </div>
  );
}