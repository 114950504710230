import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
// Images imports
import nodesImage from "../../assets/images/relayz-nodes.png";
import rewardsImage from "../../assets/images/relayz-reward.png";

// Image animation component
const SectionImage = ({ className, src, alt, x, y }) => {
    return (
      <motion.img className={className}
        whileHover={{ scale: 1.05 }}
        initial={{ x: x, y: y }}
        whileInView={{ x: 0, y: 0, transition: { duration: 1.5 } }}
        viewport={{ once: true }}
        src={src}
        alt={alt}
      />
    )
  }
  


export default function CtaNode() {
    return (

        <section className="home__section-wrapper">
            <div className="home-section home-description">
                <div className="home-section-content">
                    <h2 className="purple-text header__font--left">Node Program</h2>
                    <p className="subHeader__font">
                        Relayz node operators contribute their servers and bandwidth to the network and in return are rewarded in Relayz tokens. Find out more and <NavLink to="/network" className="purple-text">start running your node now.</NavLink>
                    </p>

                    <h2 className="purple-text header__font--left">Invite Program</h2>
                    <p className="subHeader__font">
                        We value our community and reward our members for their active participation. We offers members the opportunity to earn rewards in various ways,  <NavLink to="/invite" className="purple-text">click here to learn more.</NavLink>
                    </p>
                </div>
                <div className="home-section-images">
                    <div className="home-section-image--nodes">
                        <SectionImage
                            className="home-section-image"
                            src={nodesImage}
                            alt="Relayz nodes table"
                            x="1vw"
                            y="8vw"
                        />
                    </div>
                    <div className="home-section-image--rewards">
                        <SectionImage
                            className="home-section-image"
                            src={rewardsImage}
                            alt="Relayz Reward Program screen"
                            x="-5vw"
                            y="-5vw"
                        />
                    </div>
                </div>
            </div>
        </section>

    );
}
