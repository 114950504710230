import React, { useState, useEffect, useMemo } from "react";

import "./index.sass";
import searchIcon from "../../../assets/icons/search-icon.svg";
import NodeListItem from "../../../components/nodeRowItem";
import { useAuthContext } from "../../../contexts/AuthContext";
import useWindowDimensions from "../../../functions/useWindowDimensions";

import NodeUpdateWarning from "../../../components/nodeUpdateWarning";
import UpdateNodeModal from "../../../components/updateNodeModal";


export default function Nodes() {
  const [nodes, setNodes] = useState([]);
  // eslint-disable-next-line
  const [topRankingUsers, setTopRankingUsers] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { authenticatedUserData, isAwareOfNodeUpdate, isAuthenticated } = useAuthContext();

  const { width } = useWindowDimensions();

  const isPublic = true; // or based on some condition or prop
  const [sortedNodes, setSortedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'
  const [currentSortParam, setCurrentSortParam] = useState("");


  useEffect(() => {
    fetch("https://api.relayz.io/api/nodes")
      .then((response) => response.json())
      .then((responseData) => {
        const data = responseData.data;
        const validNodes = data
          .map((nodeItem) => {
            if (!nodeItem.ownerId) return null;

            const noTraffic =
              !nodeItem.traffic || ["0", ""].includes(nodeItem.traffic);
            const noRegion =
              !nodeItem.region || ["-", ""].includes(nodeItem.region);

            if (noTraffic && noRegion) {
              return {
                ...nodeItem,
                state: "Disabled",
              };
            }
            return nodeItem;
          })
          .filter(Boolean)
          .sort((a, b) => {
            // 1. Online/Offline Status:
            const stateOrder =
              ["Active", "Inactive", "Disabled"].indexOf(a.state) -
              ["Active", "Inactive", "Disabled"].indexOf(b.state);
            if (stateOrder !== 0) return stateOrder;

            // 2. Traffic:
            const trafficDiff = parseInt(b.traffic) - parseInt(a.traffic);
            if (trafficDiff !== 0) return trafficDiff;

            // 3. Alphabetic Order:
            return a.ownerId.localeCompare(b.ownerId);
          });

        if (validNodes.length >= 3) {
          setTopRankingUsers({
            [validNodes[0].traffic]: "1",
            [validNodes[1].traffic]: "2",
            [validNodes[2].traffic]: "3",
          });
        }
        setNodes(validNodes);
        setSortedNodes(validNodes);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // return total nodes by state
  const filteredByState = (state) =>
    nodes.filter((n) => n.state === state).length;

  const filteredNodes = useMemo(
    () =>
      nodes.filter((node) => {
        return (
          (node.ownerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            node.nodeId.toLowerCase().includes(searchTerm.toLowerCase())) &&
          node.state !== "Disabled"
        );
      }),
    [nodes, searchTerm]
  );

  //handle sorting
  const sortNodesByParam = (column) => {
    const direction =
      currentSortParam === column && sortDirection === "asc" ? "desc" : "asc";

    // Set the public or private list to sort elements
    const listToSort = nodes;

    const sortedNodes = [...listToSort]
      .sort((a, b) => {
        let result;
        switch (column) {
          case "traffic":
            result = parseInt(a.traffic) - parseInt(b.traffic);
            break;

          case "upload":
            result =
              (a.bandwidth && a.bandwidth.upload && a.bandwidth.upload.speed
                ? parseInt(a.bandwidth.upload.speed)
                : 0) -
              (b.bandwidth && b.bandwidth.upload && b.bandwidth.upload.speed
                ? parseInt(b.bandwidth.upload.speed)
                : 0);
            break;

          case "download":
            result =
              (a.bandwidth && a.bandwidth.download && a.bandwidth.download.speed
                ? parseInt(a.bandwidth.download.speed)
                : 0) -
              (b.bandwidth && b.bandwidth.download && b.bandwidth.download.speed
                ? parseInt(b.bandwidth.download.speed)
                : 0);
            break;

          default:
            result = a[column].localeCompare(b[column]);
        }

        return direction === "desc" ? -result : result;
      })
      .sort((a, b) => {
        const stateOrder =
          ["Active", "Inactive", "Disabled"].indexOf(a.state) -
          ["Active", "Inactive", "Disabled"].indexOf(b.state);
        if (stateOrder !== 0) return stateOrder;
        return a.state.localeCompare(b.state);
      });

      setNodes(sortedNodes);

    setSortDirection(direction);
  };

  const handleChangeSortParam = (param) => {
    setCurrentSortParam(param);
    sortNodesByParam(param);
  };
    

  const renderTableHeader = () => (
    <>
      <h2 className="node_table-title">Public</h2>
      <div className="node_table-header">
        <div className="node_table-header-unit">Wallet</div>
        <div
          className={`node_table-header-unit ${width <= 420 ? "node_table-header--no-mobile" : ""
            }`}
        >
          Status
        </div>
        <div
          className={`node_table-header-unit ${width <= 420 ? "node_table-header--no-mobile" : ""
            }`}
        >
          Node ID
        </div>
        <div className="node_table-header-unit">Region</div>
        <div className="node_table-header-unit node_table-header--no-mobile">
          <button
            className="node_table-header-btn-filter"
            title="Filter by Upload Speed"
            onClick={() => handleChangeSortParam("upload")}
          >
            <span>Upload Speed</span>
          </button>
        </div>
        {/* Use width to remove this div on screens equal or smaller than 620px */}
        <div
          className={`node_table-header-unit ${width <= 620 ? "node_table-header--no-mobile" : ""
            }`}
        >
          <button
            className="node_table-header-btn-filter"
            title="Filter by Download Speed"
            onClick={() => handleChangeSortParam("download")}
          >
            <span>Download Speed</span>
          </button>
        </div>
        <div className="node_table-header-unit">
          <button
            className="node_table-header-btn-filter"
            title="Filter by Traffic"
            onClick={() => handleChangeSortParam("traffic")}
          >
            <span>Traffic</span>
          </button>
        </div>
        {!isPublic && (
          <>
            <div className="table-actions node_table-header--no-mobile">
              Actions
            </div>
          </>
        )}
      </div>
    </>
  );

  return (
    <div className="body_nodes">
      <h2 className="purple-text header__font">
        RelyNet Nodes
      </h2>
      <h2>
        Become a Node Operator
        <br/>
        <br/>
      </h2>

      <p className="paragraphs">
        Node operators are the pillars of decentralized networks, ensuring its security and efficiency. By hosting a node on RelyNet Testnet, you not only uphold the network's integrity but also become eligible for rewards as an early contributor. Dive in and play a key role in our platform's growth.
      </p>
      <h2>
      <br/>
        Public Nodes Overview
        <br/>
        <br/>
      </h2>
      <p className="paragraphs">
      As our decentralized network thrives, we're proud to present an extensive list of active public nodes. These nodes, operated by dedicated members of our community, play a pivotal role in maintaining the integrity and functionality of the RelyNet. Explore the list below to view all the nodes currently running, reflecting our growing and robust network ecosystem.
      <br/>
        <br/>
        <br/>
        <br/>
      </p>

      <div className="management_node-search-public">
        <h2>
  
          Total Nodes ({nodes.length})
          <br /> Active ({filteredByState("Active")}) Inactive (
          {filteredByState("Inactive")}) Disabled ({filteredByState("Disabled")}
          )
        </h2>
        <span className="management_node-search-input">
          <input
            className="management_node-search-input-field"
            type="text"
            placeholder="Search by Wallet ID..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentSortParam("");
            }}
          />
          <button type="button">
            <img src={searchIcon} alt="Magnifying glass" />
          </button>
        </span>
      </div>
      <br/>
      <br/>
      {/* Render update warning message */}
      <NodeUpdateWarning>
        <p>
          New Relayz Node Update Available <span>Click Here to Know More</span>
        </p>
      </NodeUpdateWarning>

      <div className="node_header">{renderTableHeader()}</div>

      <div className="node_table-list">
        {filteredNodes.map((node) => (
          <NodeListItem
            key={node.nodeId}
            node={node}
            getCurrentNode={setSelectedNode}
            selectedNode={selectedNode?.nodeId === node.nodeId}
            width={width} // make sure width is defined or pass it appropriately
            userData={authenticatedUserData} // make sure authenticatedUserData is defined
            isNodeOwner={false}
            rankingPosition={(sortedNodes.indexOf(node) + 1).toString()} // or any other logic you want for ranking
          />
        ))}
      </div>
      {isAuthenticated && !isAwareOfNodeUpdate && <UpdateNodeModal />}
    </div>
  );
}
