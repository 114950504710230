import "./index.sass";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import React from 'react';
import { AnimatePresence, motion, useCycle } from "framer-motion";
import {
  burgerItemVariants,
  burgerSideVariants,
} from "../../functions/animations";
import { ReactComponent as Logo } from "../../assets/images/relayz-logo.svg";
import Subheader from "./Subheader";
import { useAuthContext } from "../../contexts/AuthContext";
import SubMenu from "../subMenu";




export default function Header() {
  const [burgerOpen, burgerCycleOpen] = useCycle(false, true);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState({});
  const { isAuthenticated, logOut } = useAuthContext();
  const [expandedItem, setExpandedItem] = useState(null);


  const links = useMemo(
    () => [
      {
        title: "Products", to: "products"
      },
      {
        title: "Network", to: "network",
        subLinks: [
          "Testnet",
          "Nodes",
          "Downloads",
          "Incentive Program",
        ]
      },
      { title: "About", to: "about" },
      { title: "Invite", to: "invite" },
      {
        title: "Log In",
        external: true,
        alwaysActive: true,
        to: "/nodemanagement",
        alignRight: true,
      },
      {
        title: "Meet Now",
        external: true,
        alwaysActive: true,
        to: `https://meet.relayz.io`,
        alignRight: true,
      },
    ],
    []
  );
  // Function to logout the user by sign out button click
  const handleLogOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error("Log out failed:", error);
    }
  }

  useEffect(() => {
    const activeRoute = location.pathname.split("/")[1] || null;
    const foundLink = links.find((link) => link.to === activeRoute);
    setActiveLink(foundLink);
  }, [location, links]);

  const renderInternalLinks = () =>
    links
      .map((link) => {
        if (link.external) {
          return null
        }
        return (
          <NavLink
            key={`internal-${link.title}`}
            to={link.to}
            end={link.to === "/"}
            className={({ isActive }) =>
              isActive
                ? `header-nav-link header-nav-link-active ${link.title === "Rewards Program" ? "purple" : ""}`
                : `header-nav-link ${link.title === "Invite" ? "isActive purple-text" : ""}`
            }
          >
            {link.title}
          </NavLink>

        )
      });

  const BurgerIcon = () => (
    <svg
      className="header-mobile-burger-open"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78 78"
      onClick={burgerCycleOpen}
    >
      <circle
        className="cls-1 header-mobile-burger-open-element"
        cx="39"
        cy="39"
        r="39"
        fill={burgerOpen ? "#B399FF" : "#66458E"}
      />
      <circle
        cx="39"
        cy="39"
        r="36.08"
        className="header-mobile-burger-open-element"
        fill={burgerOpen ? "#66458E" : "#B399FF"}
      />
      <rect
        className="cls-1 header-mobile-burger-open-element"
        x="16"
        y="24.52"
        width="46"
        height="8.48"
        rx="4.24"
        fill={burgerOpen ? "#B399FF" : "#66458E"}
      />
      <rect
        className="cls-1 header-mobile-burger-open-element"
        x="22"
        y="38.67"
        width="34"
        height="7.33"
        rx="3.66"
        fill={burgerOpen ? "#B399FF" : "#66458E"}
      />
      <rect
        className="cls-1 header-mobile-burger-open-element"
        x="27"
        y="51.69"
        width="24"
        height="6.31"
        rx="3.16"
        fill={burgerOpen ? "#B399FF" : "#66458E"}
      />
    </svg>
  );

  return (
    <>
      <header className="headers">
        <div className="header">
          <NavLink to="/">
            <Logo className="header-logo" />
          </NavLink>
          <nav className="header-nav">
            <div className="nav-component">{renderInternalLinks()}</div>
            <SubMenu logOut={handleLogOut} />
          </nav>
        </div>
        {activeLink && activeLink.subLinks && (
          <Subheader activeLink={activeLink.to} links={activeLink.subLinks} />
        )}
      </header>
      <div className="header-mobile-wrapper">
        <div className="header-mobile">
          <BurgerIcon />
        </div>
        <AnimatePresence>
          {burgerOpen && (
            <>
              <div className="header-mobile-overlay" onClick={burgerCycleOpen} />
              <motion.aside
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0, transition: { delay: 0.8, duration: 0.3 } }}
                className="header-mobile-burger"
              >
                <motion.div
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={burgerSideVariants}
                  className="header-mobile-burger-items"
                >
                  {links.map((link) => {
                    if (isAuthenticated && link.title === "Log In") {
                      return (
                        <motion.button
                          key={link.title}
                          variants={burgerItemVariants}
                          transition={{ duration: 0.2 }}
                          className="header-mobile-burger-item-link header-mobile-burger-item-signout"
                          onClick={() => handleLogOut()}
                        >
                          Log Out
                        </motion.button>
                      )
                    }
                    return (
                      <div className="header-mobile-burger-item" key={link.title}>
                        {link.title === "Network" ? (
                          <>
                            <motion.div
                              onClick={() => {
                                if (expandedItem === link.title) {
                                  setExpandedItem(null);
                                } else {
                                  setExpandedItem(link.title);
                                }
                              }}
                              className={`header-mobile-burger-item-link ${expandedItem === link.title ? 'sublink-active' : ''}`}
                              variants={burgerItemVariants}
                              transition={{ duration: 0.2 }}
                            >
                              <span className="arrow-icon">
                                {expandedItem === link.title ? "▴" : "▾"}
                              </span>&nbsp;
                              {link.title}

                            </motion.div>
                            <div className={`header-mobile-burger-hidden ${expandedItem === link.title ? "burger-expanded" : ""}`}>
                              {link.subLinks.map((sublink) => (
                                <motion.div
                                  key={sublink}
                                  onClick={burgerCycleOpen}
                                  className="header-mobile-burger-item-sublink"
                                  variants={burgerItemVariants}
                                  transition={{ duration: 0.2 }}
                                >
                                  <NavLink
                                    className="header-mobile-burger-item-sublink"
                                    key={sublink}
                                    to={`/network/${sublink.toLowerCase()}`}
                                  >
                                    {sublink}
                                  </NavLink>
                                </motion.div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <motion.div
                              onClick={burgerCycleOpen}
                              className="header-mobile-burger-item-link"
                              variants={burgerItemVariants}
                              transition={{ duration: 0.2 }}
                            >
                              <NavLink
                                key={`internal-${link.title}`}
                                to={link.to}
                                end={link.to === "/"}
                                isActive={(match, location) => {
                                  return location.pathname === link.to;
                                }}
                                activeClassName="active"
                              >
                                {link.title}
                                {link.subLinks && (
                                  <span className="expand-toggle" onClick={(e) => {
                                    e.stopPropagation(); // Prevents burgerCycleOpen from being triggered
                                    if (expandedItem === link.title) {
                                      setExpandedItem(null); // collapse if the same link is clicked again
                                    } else {
                                      setExpandedItem(link.title); // expand the clicked link
                                    }
                                  }}>
                                  </span>
                                )}
                              </NavLink>
                            </motion.div>
                            {link.subLinks && (
                              <div className={`header-mobile-burger-sublinks ${expandedItem === link.title ? 'burger-expanded' : ''}`}>
                                {link.subLinks.map((sublink) => (
                                  <motion.div
                                    key={sublink}
                                    onClick={burgerCycleOpen}
                                    className="header-mobile-burger-item-sublink"
                                    variants={burgerItemVariants}
                                    transition={{ duration: 0.2 }}
                                  >
                                    <NavLink
                                      className="header-mobile-burger-item-sublink"
                                      key={sublink}
                                      to={link.to + '/' + sublink.toLowerCase()}
                                    >
                                      {sublink}
                                    </NavLink>
                                  </motion.div>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </motion.div>
              </motion.aside>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
