import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import StoreContext from "../store/RootStore";
import useLoginWithMetamask from "../functions/loginWithMetamask";
import nearUserAvatar from "../assets/images/web3/near-wallet.svg";
import metamaskUserAvatar from "@metamask/jazzicon";
import Cookies from "js-cookie";
// import CryptoJS from "crypto-js";




const AuthContext = createContext();

// eslint-disable-next-line 
const storageKey = "@appAuth";
const masterAccounts = process.env.REACT_APP_MASTER_ACCOUNT_IDS?.split(',');

// Authentication provider
function AuthContextProvider({ children }) {
  const authUserData = JSON.parse(localStorage.getItem(storageKey));
  const [isAwareOfNodeUpdate, setIsAwareOfNodeUpdate] = useState(false);
  const [isAdmin, setIsAdmin] = useState(
    masterAccounts?.includes(authUserData?.address) ? true : false
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    Boolean(!!authUserData?.address)
  ); 

  // This function will set a cookie if the user choose to update the node 
  // It will expires after 15 days then it will remind user again
  const postponeUpdate = (agreeToPostpone) => {
    if (agreeToPostpone) {
      setIsAwareOfNodeUpdate(true);
    } else {
      Cookies.set("awareOfNodeUpdate", true, { expires: 15 });
      setIsAwareOfNodeUpdate(true);
    }
  }

  const [authenticatedUserData, setAuthenticatedUserData] = useState(
    JSON.parse(localStorage.getItem(storageKey))
      ? JSON.parse(localStorage.getItem(storageKey))
      : null
  );

  const verifyAdminUser = (address) => {
    const masterAccounts = process.env.REACT_APP_MASTER_ACCOUNT_IDS?.split(',');
    const isMasterAccount = masterAccounts.includes(address);
    if (isMasterAccount) {
      setIsAdmin(true);
      // console.log("Master Account", authUserData.address);
    } else {
      // console.log("Not a Master Account", authUserData.address);
      setIsAdmin(false);


    setIsAdmin(false);
  }
};

  const { checkMetamaskLogin, loginWithMetamask } = useLoginWithMetamask();
  const { FunctionStore, AuthStore } = StoreContext();


  // this function will create a base64 encoded text to be used in the localStorage
  const generateUserAvatar = (loginType) => {
    if (loginType === "near") {
      return nearUserAvatar;
    }

    // This code will create a div element with a svg element inside
    const avatarElement = metamaskUserAvatar(100, Math.round(Math.random() * 10000000));
    // This code will get the svg element inside the div and convert it into base64 string
    const svgElement = avatarElement.querySelector('svg');
    const serializer = new XMLSerializer();
    const svgXml = serializer.serializeToString(svgElement);
    // This part of the code will mount the final base64 string to be used in a img element
    const base64Encoded = `data:image/svg+xml;base64,${btoa(svgXml.toString())}`;
    return base64Encoded;
  }
  
  // sets the authentication state inside the localStorage
  const setAuthenticationData = useCallback(() => {
    const userData = AuthStore.getUserData();
    const walletData = JSON.parse(localStorage.getItem(storageKey));
    if (userData.address) {
      if (!walletData) {
        const avatar = generateUserAvatar(userData.loginType);
        localStorage.setItem(storageKey, JSON.stringify({ ...userData, avatar }));
        setAuthenticatedUserData({ ...userData, avatar });
      }
      setIsAuthenticated(true);

      // Send POST request to register user in the database
      sendPostRequest(userData);
    }
  }, [AuthStore]);

  const sendPostRequest = async (userData) => {
    try {
      // Log the address for troubleshooting
      // console.log("Address:", userData.address);

      const response = await fetch("https://api.relayz.io/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountId: userData.address,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send post request");
      }

      // Log a success message for troubleshooting
      // console.log("Success! Data sent to the server.");

      // You can also handle redirection here if needed
    } catch (error) {
      console.error("Error:", error);
      // Handle any error that occurred during the fetch
    }
  };

  // removes the authentication state from the localstorage
  const removeAuthenticationData = () => {
    localStorage.removeItem(storageKey);
    setIsAuthenticated(false);
  };

  const logOut = async () => {
    const loginType = AuthStore?.getLoginType();

    if (loginType === "near" || !loginType) {
      removeAuthenticationData();
      await FunctionStore?.clearNearData();
    }

    if (loginType === "metamask") {
      AuthStore?.logOut();
      removeAuthenticationData();
    }
  };

  const metamaskLogin = async () => {
    await loginWithMetamask();
    setAuthenticationData();
  };

  const nearLogin = async () => {
    await FunctionStore.loginWithNear();
  };

  useEffect(() => {
    // console.log('01');
  
    // Define the function that was previously using useCallback
    const checkUserAuthentication = async () => {
        const userData = AuthStore.getUserData();
  
      if (userData && userData.address) {
        // console.log(`Contract ID: ${userData.publicKey}`);
        // console.log(`address`, userData.address);
        setAuthenticationData();
      } else {
        // User is not authenticated
        // Actions if user is not authenticated could go here
      }
    };
  
    // Asynchronous function to initialize authentication and check wallet
    const init = async () => {
      await FunctionStore.checkNearLogin();
      await checkUserAuthentication();
      checkMetamaskLogin();
    };
  
    // Call the initialization function
    init();
  
    // Logic that depends on the result of checkUserAuthentication
    const walletData = JSON.parse(localStorage.getItem(storageKey));
    const userData = AuthStore.getUserData();
    // console.log('userData.address:', userData.address);
    // console.log('walletData:', walletData);
    // console.log('Type of walletData:', typeof walletData);
    // console.log('userData:', userData);
  
    if (userData.address && walletData && walletData !== "" && walletData !== null) {
      // console.log('02');
      setIsAuthenticated(true);
      verifyAdminUser(userData.address);
    }
    // eslint-disable-next-line 
    }, [isAuthenticated, AuthStore, setAuthenticationData, FunctionStore, checkMetamaskLogin, storageKey, verifyAdminUser]);
  


  // Function created to verify if the user logged out using the metamask plugin directly
  // without clicking the sign out button in the website
  useEffect(() => {
    if (window.ethereum) {
      // Ethereum event listener to verify changes on wallet (reconnection, disconnect, another account)
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          // User disconneted from MetaMask
          AuthStore?.logOut();
          removeAuthenticationData();
          logOut();
        } else {
          // Verify changes on wallet maybe make another login request
        }
      });
    }
    // Removing event listener
    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners("accountsChanged");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // verifies if the user is authenticated


  // It verifies if the user is aware of the node update notification
  useEffect(() => {
    const awareOfNodeUpdate = Cookies.get("awareOfNodeUpdate");

    if (awareOfNodeUpdate) {
      setIsAwareOfNodeUpdate(true);
    }
  }, [isAwareOfNodeUpdate]);

  return (
    <AuthContext.Provider
          value={{
            isAuthenticated,
            authenticatedUserData,
            nearLogin,
            logOut,
            metamaskLogin,
            postponeUpdate,
            isAwareOfNodeUpdate,
            isAdmin
          }}
        >
          {children}
    </AuthContext.Provider>
  );
}

// hook used to access the shared functions and variables
function useAuthContext() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthContextProvider, useAuthContext };
