/* eslint-disable jsx-a11y/anchor-is-valid */
import "./index.sass";
import React, { useState } from 'react';
import { useAuthContext } from "../../../contexts/AuthContext";
import UpdateNodeModal from "../../../components/updateNodeModal";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight, tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import moon from "../../../assets/icons/moon.svg";
import sun from "../../../assets/icons/sun.svg";
import NodeUpdateWarning from "../../../components/nodeUpdateWarning";
import PauseNodesModal from "../../../components/pauseNodesModal";




// eslint-disable-next-line
function DownloadLink({ href, children }) {
  return (
    <div>
      <a className="purple-text" href={process.env.PUBLIC_URL + href}>
        {children}
      </a>
      <br />
    </div>
  );
}

function InstallationInstructions() {
  const [isExpandedA, setIsExpandedA] = useState(false);
  const [isExpandedB, setIsExpandedB] = useState(false);
  const [isExpandedInstallation, setIsExpandedInstallation] = useState(false);

  return (
    <section className="downloads__instructions">
      <br />
      <h2  className="bold clickable" onClick={(e) => { e.stopPropagation(); setIsExpandedInstallation(!isExpandedInstallation); }}>Installation Instructions {isExpandedInstallation ? "▴" : "▾"} </h2>
      <div>
        <section className={`downloads__requirements ${isExpandedInstallation ? 'downloads__requirements--expanded' : ''}`}>
          <div>
            <div className="card--instructions-wrapper indented margin__top margin__bottom">
              <div className="card__side-by-side--wrapper">
                <div className="card__side-by-side--item">
                  <div onClick={() => { setIsExpandedA(!isExpandedA); }}>
                    <div className="instructions__option indented">
                      <h1>A</h1>
                    </div>
                    <h2 className="bold line__spacing clickable" onClick={(e) => { e.stopPropagation(); setIsExpandedInstallation(!isExpandedInstallation); }}>
                      Instructions for Option A {isExpandedA ? "▴" : "▾"}
                    </h2>
                   
                  </div>
                  <p className="paragraphs margin__top">
                    Using binary files
                    </p>

                  <section className={`downloads__requirements ${isExpandedA ? 'downloads__requirements--expanded' : ''}`}>
                    <div className="card--instructions margin__top">
                      <p className="line__spacing paragraphs">1 - Download the CLI binary file 
                      <br />(<a className="purple-text" href="/resources/files/binaries/aarch64-prod-linux-node-cli.zip" > ARM </a>
                      or <a className="purple-text" href="/resources/files/binaries/x86_64-prod-linux-node-cli.zip" >X86_64 </a>)</p>
                   
                      <p className="line__spacing paragraphs">
                        2 - Install docker and docker compose. <br/>Click <a className="purple-text" href="https://mazer.dev/en/docker/introduction/how-to-install-docker-and-docker-compose-on-linux-ubuntu-mint-debian/"> here{" "}</a> or <a className="purple-text" href="https://computingforgeeks.com/install-docker-and-docker-compose-on-debian/">here{" "}</a> for the guides.
                      </p>
                      <p className="line__spacing paragraphs">
                        3 - Open up a terminal and run the following command
                        replacing the account_ID with your NEAR testnet walletID:
                        <br />
                        sudo ./node-cli init (ACCOUNT_ID) <span className="italic">example: sudo ./node-cli init yourwallet.testnet</span>
                      </p>
                      <p className="line__spacing paragraphs">
                        4 - Follow the prompts to add a key and authenticate your
                        wallet
                      </p>
                      <p className="line__spacing paragraphs">
                        5 - Once that’s done you should see your node
                        displayed as registered here{" "}
                        <a className="purple-text" href="/network/nodes">
                          {" "}
                          Relayz Network Nodes
                        </a>
                      </p>

                    </div>
                  </section>
                </div>
                <div className="card__side-by-side--item">
                  <div onClick={() => { setIsExpandedB(!isExpandedB); }}>
                    {/* <div className="instructions__option indented">
                      <h1>B</h1>
                    </div>
                    <h2 className="bold line__spacing clickable" onClick={(e) => { e.stopPropagation(); setIsExpandedInstallation(!isExpandedInstallation); }}>
                      Instructions for Option B {isExpandedB ? "▴" : "▾"}
                    </h2>
                    {/* <p className="paragraphs margin__top"> Using a Virtual Machine by downloading<br /> the .ova file<span><a className="purple-text" href={process.env.PUBLIC_URL + "/resources/files/binaries/vm_1.2.ova"}> here </a>{" "}</span></p> */}


                    <p className="paragraphs margin__top"> Using a Virtual Machine by downloading<br /> the .ova file.<span>{" "}</span></p>


                  </div>
                  <section className={`downloads__requirements ${isExpandedB ? 'downloads__requirements--expanded' : ''}`}>
                    <div>
                      <div className="card--instructions">
                        <p className="line__spacing paragraphs margin__top">
                          1 - Install your virtual machine client of choice (VMWare,
                          Virtuabox, etc)
                        </p>
                        <p className="line__spacing paragraphs">
                          2 - Download the node .ova file from the link above.
                        </p>
                        <p className="line__spacing paragraphs">
                          3 - Open up VMWare/Virtuabox and import the .ova file
                        </p>
                        <p className="line__spacing paragraphs">
                          4 - Start the imported VM
                        </p>
                        <p className="line__spacing paragraphs">
                          5 - Wait 1 minute and open 127.0.0.1:5000 in your local browser and follow the instructions
                        </p>
                        <p className="line__spacing paragraphs">
                          6 - Once that’s done you should see your node displayed as registered here{" "}
                          <a className="purple-text" href="/network/nodes">
                            {" "}
                            Relayz Network Nodes
                          </a>
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

function MonitoringInstructions() {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <section className={`downloads__requirements ${isExpanded ? 'downloads__requirements--expanded' : ''}`}>
      <h2 className="bold clickable" onClick={() => setIsExpanded(!isExpanded)}>
        Monitoring {isExpanded ? "▴" : "▾"}
      </h2>
      <div>

        <div className="indented margin__top margin__bottom" >
          <p className="line__spacing paragraphs">
            1- You can see if your node was successfully registered here{" "}
            <a href="/network/nodes" target="_blank" className="purple-text" rel="noreferrer">
              Relayz Network Nodes
            </a>
          </p>
          <p className="line__spacing paragraphs">
            2- You can also verify locally on your node server (docker
            local)
          </p>
        </div>

      </div>
    </section>
  );
}



function MinimumRequirements() {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <section className={`downloads__requirements ${isExpanded ? 'downloads__requirements--expanded' : ''}`}>
      <h2 className="bold clickable" onClick={() => setIsExpanded(!isExpanded)}>
        Minimum Requirements {isExpanded ? "▴" : "▾"}
      </h2>

      <div>

        <div className="indented margin__top margin__bottom" >

          <p className="paragraphs">
            If you want to run a node you will need a machine that is
            powerful enough to facilitate all of the required operations.
            <br />
            From a decentralization standpoint we believe it is best to run
            the node on your own machine.
            <br />
            The setup below is a reasonable suggestion that should allow you
            to run your node well.
            <br />
          </p>

          <div className="indented" >
            <p className="paragraphs">
          <div className="indented" >
            <p className="paragraphs">
              <br />
              CPU: modern desktop x86_64 (Intel, ARM) processor with at least 4
              cores
              <br />
              RAM: 8GB (16GB is better)
              <br />
              Storage: 100GB
              <br />
              Network: 100+Mbps
              <br />
            </p>
          </div>
            </p>
          </div>

          <p className="paragraphs">
            <br />
            We currently support the installation of Relayz nodes on these Linux distributions: Debian 11, Ubuntu 22.04 LTS, and Manjaro.
            <br />
            Please make sure that the network has low latency and is
            reliable.
            <br />
            If you need support, contact us at{" "}
            <a href="mailto:info@relayz.io" className="purple-text">
              help@relayz.io
            </a>
          </p>

        </div>

      </div>
    </section>
  );
}


const codeString = `
# stops all running docker containers
docker stop $(docker ps -a -q)
  
# removes all docker containers.
docker rm $(docker ps -a -q)
  
# deletes all docker images.
docker rmi $(docker images -a -q)
  
# cleans up your docker
docker system prune -a
  `;

function UpdateInstructions() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  return (
    <section className={`downloads__requirements ${isExpanded ? 'downloads__requirements--expanded' : ''}`}>
      <h2 className="bold clickable" onClick={() => setIsExpanded(!isExpanded)}>
        Update Instructions {isExpanded ? "▴" : "▾"}
      </h2>
      <div>

        <div className="indented margin__top margin__bottom" >

          <p className="line__spacing paragraphs">
            1 - Download the latest binary for node-cli
          </p>
          <p className="line__spacing paragraphs">
            2 - Execute it normally (don't forget to use sudo if you're not root)  ./ sudo node-cli init [yournodewallet.testnet[]
          </p>
          <p className="line__spacing paragraphs">
            3 - Wait patiently for the node cleanup and redeploy to finish (may take some time)
          </p>
          <p className="line__spacing paragraphs">
            4 - The node-cli binary will try to bring down your old node containers, delete them and then redeploy the newest version seamlessly.
            <br />
            You should not have to update any keys since it will use your current wallet keys.
            <br />
            If you're updating a node (instead of a new installation from scratch)
            <br />
            These keys should be already present in your home folder with the filename .secret.json.
            <br />
            It is always a good idea to have a backup of this .secret.json file.
            <br />
          </p>
          <p className="line__spacing paragraphs">
            5 - Should anything go wrong during the update, don't panic.
            <br />
            As long as you have your .secret.json file you're good to go.
            <br />
            All you need to do is run the following commands on your server (sudo or root) to delete everything and deploy the new node:
            <br />
            <br />

          </p>
          <div className="darkmode__wrapper darkmode__icon-item clickable italic">
            <p onClick={() => setIsDarkMode(prevMode => !prevMode)}>
              {isDarkMode ? 'dark mode' : 'light mode'}
            </p>
            <button className="darkmode__icon-button" onClick={() => setIsDarkMode(prevMode => !prevMode)}>
              <img className="darkmode__icon-item" src={isDarkMode ? sun : moon} alt={isDarkMode ? "Sun" : "Moon"} />
            </button>
          </div>


          <div className="line__spacing indented">


            <SyntaxHighlighter language="bash" style={isDarkMode ? tomorrow : solarizedlight}>
              {codeString}
            </SyntaxHighlighter>
            <p className="italic"> <span className="purple-text "> *** </span> Warning: make sure you don't have any non-relayz containers on your server since they will also be deleted</p>
          </div>
          <p className="line__spacing paragraphs">
            <br />
            Once that's done your server should be clean so go ahead and run the node-cli binary again (don't forget sudo if not root)
            <br />
            sudo node-cli init [yournodewallet.testnet]
            <br />
          </p>

          <p className="line__spacing paragraphs">
            *Your node should now be updated and registered on our smart contract ready to receive traffic
          </p>

        </div>

      </div>
    </section>
  );
}

export default function Downloads() {
  const { isAwareOfNodeUpdate, isAuthenticated } = useAuthContext();
  const [showPauseNodesModal, setShowPauseNodesModal] = useState(true);

  const handlePauseNodesModal = () => {
    setShowPauseNodesModal(false);
  }

  return (
    <div>
      <div className="downloads-container">
        <div className="network__article network__sections">
          <h1 className="purple-text header__font">Testnet Node Downloads</h1>
          <section className="margin__bottom">

            <br />
            <div className="downloads__wrapper">

              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/x86_64-prod-linux-node-cli.zip"}>
                    <p>X86</p>
                  </a>
                  {/* <a aria-label="button" >
                    <p>X86</p>
                  </a> */}
                </div>
                <DownloadLink href="/resources/files/binaries/x86_64-prod-linux-node-cli.zip">
                  <p className="paragraphs">Linux Download (X86 and X64)</p>
                </DownloadLink>
                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (X86 and X64)</a>
                </div> */}
              </div>


              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/aarch64-prod-linux-node-cli.zip"}>
                    <p>ARM</p>
                  </a>
                  {/* <a aria-label="button">
                    <p>ARM</p>
                  </a> */}
                </div>
                <DownloadLink href="/resources/files/binaries/aarch64-prod-linux-node-cli.zip">
                  <p className="paragraphs">Linux Download (ARM Processors)</p>
                </DownloadLink>

                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (ARM Processors)</a>
                </div> */}
              </div>


              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/vm_1.2.ova"}>
                    <p>VM</p>
                  </a>
                  {/* <a aria-label="button" >
                    <p>VM</p>
                  </a> */}
                </div>

                <DownloadLink href="/resources/files/binaries/vm_1.2.ova">
                  <p className="paragraphs">Linux Download (Virtual Machine)</p>
                </DownloadLink>

                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (Virtual Machine)</a>
                </div> */}

              </div>
            </div>
            <br />
            <NodeUpdateWarning className="margin__top">
              <p>
               New Nodes registration is temporarily suspended.
              </p>
            </NodeUpdateWarning>
          </section>
          <section className="downloads__section__wrapper">
            <InstallationInstructions />
            <MonitoringInstructions />
            <MinimumRequirements />
            <UpdateInstructions />
            <br />

            {/* Render update node warning message */}
            <NodeUpdateWarning>
              <p>
                New Relayz Node update available, click on the Update Instructions section above.
              </p>
            </NodeUpdateWarning>
          </section>
        </div>
        <br />
        <br />
        <br />
      </div>
      {isAuthenticated && !isAwareOfNodeUpdate && <UpdateNodeModal />}
      {showPauseNodesModal && <PauseNodesModal handlePauseNodesModal={handlePauseNodesModal} /> }
    </div>

  );
}
