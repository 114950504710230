// external imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

// local imports
import { useAuthContext } from '../../contexts/AuthContext';
import { fadeInAnimation } from '../../functions/animations';

export default function UpdateNodeModal () {
  const [hideModal, setHideModal] = useState(false);
  const { postponeUpdate } = useAuthContext();
  const navigate = useNavigate();

  const handleAcceptUpdate = () => {
    setHideModal(true);
    postponeUpdate(false);
    navigate('/network/downloads'); 
  };

  const handlePostpone = () => {
    setHideModal(true);
    postponeUpdate(true);
  }  

  if (hideModal) {
    return null;
  }

  return (
    <div className="modal">
      <motion.div 
        className="modal__box"
        initial="hidden"
        animate="visible"
        variants={fadeInAnimation}
      >
        <h2>Node Update Available</h2>
        <h4>Great news! There's a new version available for your node.</h4>
        <p>
          <span>Enhanced Performance:</span>Ensure the best possible performance, security, and features by staying up-to-date.
          <span>Higher Reward Opportunity:</span> Nodes with the most recent version are preferred by the network, which means a chance for you to earn higher rewards.
          Don't miss out on these benefits. Update now and optimize your node's potential.
        </p>
        <div className="modal__buttons">
          <button className='modal-button' onClick={handleAcceptUpdate}>Update now</button>
          <button className='modal-button' onClick={handlePostpone}>Remind me later</button>
        </div>
      </motion.div>
    </div>
  );
}
