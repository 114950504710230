import { useState, useLayoutEffect } from "react";
import WalletConnectionSection from "../../../components/walletSelection";
import { useAuthContext } from "../../../contexts/AuthContext";

export default function Login() {
  // created a new state to be able to show the new component section dinamically
  const [agreedToConnect, setAgreedToConnect] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const storedReferralId = localStorage.getItem("referralId");


  // Modified this function to show the wallet component when the user click on connect button
  const handleConnectWalletClick = () => {
    // Navigate to the /profile route when the button is clicked
    setAgreedToConnect(true);
  };



  useLayoutEffect(() => {
    if(isAuthenticated) {
      localStorage.removeItem("referralId");
      window.location.href = "/nodemanagement/userprofile";
      return
    }
    if(storedReferralId) {
      window.location.href = `/welcome/${storedReferralId}`;
    }
  }, [isAuthenticated, storedReferralId]);



  return (
    <>
      {!agreedToConnect ? (
        <div className="login_card">
          <div className="login_card-title">
            <h2>Relayz Secure Authentication</h2>
          </div>
          <div className="login_card-subtitle">
            <h3>
              We need to connect your wallet before you can have access to authenticated areas.
            </h3>
          </div>
          <div className="login_card-button"> 
            <button
              type="button"
              className="home-description-cta-button-navi"
              onClick={handleConnectWalletClick}
            >
              Connect Wallet
            </button>
          </div>
        </div>
      ) : (
        <WalletConnectionSection />
      )}
    </>
  );
}
