// Local components imports
import Footer from "../../components/Footer";
// import CtaRewardProgram from "../../components/ctaRewardProgram";
import CtaGalRewards from "../../components/ctaGalRewards";
//import AdvertsSection from "../../components/advertsSection";
import CtaRelayzMeet from "../../components/ctaRelayzMeet";
import CtaMeet from "../../components/ctaMeet";
import CtaNode from "../../components/ctaNode";





// Styles imports
import "./index.sass";


export default function Home() {
  return (

    <div className="home">

  {/* Call to action rewards program section */}
  {/*<CtaRewardProgram />*/}

    {/* Call to action rewards program section */}
  <CtaGalRewards />

      {/* Hero section */}
      <CtaMeet />


      {/* Relayz Meet section */}
      <CtaRelayzMeet />

      {/* Node and reward program section */}
      <CtaNode />

      {/* News and twitter feed section */}
      {/*<AdvertsSection />*/}


      <div className="home-footer-wrapper">
        <Footer />
      </div>
    </div>
  );
}