import { BASE_URL } from "./api";

const nodeState = {
  activate: "activate",
  deactivate: "deactivate",
  disable: "disable"
};

const getAllNodes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/nodes`);
    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const changeNodeState = async (nodeId, status) => {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
  };

  try {
    if(nodeId && nodeState[status]) {
      const response = await fetch(`${BASE_URL}/nodes/${nodeId}/state/${nodeState[status]}`, options);
      const { data } = await response.json();
      return data;
    } else {
      throw new Error("Invalid parameters.");
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export { getAllNodes, changeNodeState };