import "./index.sass"
import PageContainer from "../../components/pageContainer"
import NodeUpdateWarning from "../../components/nodeUpdateWarning"

export default function NodeDownloadWarningPage() {
    return (
        <PageContainer>
            <div>
                <section className="page-content">

               
                    <h2 className="purple-text header__font ">Testnet Registration of New Nodes Temporary Suspension</h2>
                    <br />
                    <NodeUpdateWarning className="margin__top">
                        <p>
                        Registration for new Nodes suspended
                        </p>
                    </NodeUpdateWarning>
                    <p className="paragraphs margin__top">
                        <br/>
                        We are temporarily halting the registration of new nodes as we address certain issues. In the meantime, we encourage you to check our website and stay connected with our community for updates.
                        <br />
                        <br />
                        We greatly value your commitment to our network and will keep you informed of any developments.
                        <br />
                        <br />
                        <br />
                        Best regards,<br />
       
                        Relayz Team
                    </p>
                </section>
            </div>
        </PageContainer>
    )
}