import "./index.sass";

export default function Overview() {
  return (
    <div className="">
      <div className="network__article network__sections">
        <div>
          <h2
            className="purple-text header__font"
            id="overview"
          >
            Relayz Network Overview
          </h2>
          <h2>
            The Essence of Relayz: RelyNet & RELY Token
            <br />
            <br />
          </h2>
          <p className="paragraphs">
            The Relayz Network, fondly referred to as RelyNet, is our pioneering initiative to foster the birth of privacy-preserving applications. Nestled at the very core of Relayz, the RelyNet, in tandem with the RELY token, serves as the lifeblood, ushering in a new era of privacy, incentivization, and decentralization to our tech infrastructure.
            <br />
            <br />
            RelyNet thrives thanks to the dedication of the Relayz Node Network - an intricate web of nodes overseen by our fervent Relayz community. Each node springs to life with the staking of RELY tokens, translating into a voluntary commitment of a specified token amount.
            <br />
            <br />
            Our vision? To transition from the initially centralized RelyNet to a vast decentralized expanse devoid of any singular control or potential failure points, ensuring an unbreakable shield against censorship.
            <br />
            <br />
            As stewards of the Relayz network, node operators dedicate their servers and bandwidth, and in reciprocity, are remunerated with RELY tokens. This synergistic relationship not only fuels the demand for RELY tokens but also catalyzes the evolution of a truly global and decentralized network.
          </p>
        </div>

        <div>
          <h2 className="purple-text header__font">
            Embarking on the Journey
          </h2>
          <h2>
            Staking & Node Operation
            <br />
            <br />
          </h2>
          <p className="paragraphs">
            At Relayz, staking isn't merely a procedure; it's our way of fortifying the platform's sanctity. Node operators anchor the network by staking a minimum of 5,000 RELY tokens as collateral, a commitment that empowers them to offer:
            <br />
            <br />
            1 - Routing Services
            <br />
            <br />
            2 - Bandwidth Provisions
            <br />
            <br />
            In the world of Relayz Meet, post-call experiences matter. Users get to rate their call quality (on a 1 to 5-star scale). These ratings funnel into a smart contract, shaping the reputation scores of nodes. Nodes with compromised integrity face reduced rewards, and those with malicious intent risk both omission from routing calls and a forfeiture of their staked tokens. This structure discourages any malicious behavior, making it financially taxing for any perpetrator.
            <br />
            <br />
            A node's integration into the Relayz Network is marked by the staking of the requisite RELY amount. Upon network acknowledgment, the node is primed to commence its reward journey.
          </p>
        </div>

        <div>
          <h2 className="purple-text header__font">
            Incentivizing the Future
          </h2>
          <h2>
            Network Rewards
            <br />
            <br />
          </h2>
          <p className="paragraphs">
            Our Relayz Network Incentive scheme exists to salute your contribution. The moment you embed a node within our network, you embark on a daily reward journey.

            While we're in the throes of our Testnet roll-out, an incentive program runs parallelly. Stay tuned, as we're gearing up to unveil comprehensive details on the mainnet incentives as we inch closer to its grand launch.
            <br />
            <br />
            Explore the testnet
            <span>
              {" "}
              <a href="/network/incentive Program" className="purple-text">
                Relayz Reward
              </a>
            </span>{" "}
            program and start receiving rewards.
            <br />
            <br />
          </p>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
