import "./index.sass";

import productImage01 from "../../../assets/images/sectionIcons/products01.png";
import productImage02 from "../../../assets/images/sectionIcons/products02.png";
import productImage04 from "../../../assets/images/sectionIcons/products04.png";
import productImage05 from "../../../assets/images/sectionIcons/products05.png";
import productImage06 from "../../../assets/images/sectionIcons/products06.png";
import productImage07 from "../../../assets/images/sectionIcons/products07.png";
import productImage09 from "../../../assets/images/sectionIcons/how01.png";
import productImage10 from "../../../assets/images/sectionIcons/how02.png";
import productImage11 from "../../../assets/images/sectionIcons/how03.png";
import productImage12 from "../../../assets/images/sectionIcons/how04.png";

import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

function observeIntersection(cssInitialClass, cssFinalClass) {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(cssFinalClass);
        }
        // } else {
        //   entry.target.classList.remove(cssFinalClass);
        // }
      });
    },
    { threshold: 0.95 }
  );

  const fadeElements = document.querySelectorAll(`.${cssInitialClass}`);
  fadeElements.forEach((element) => {
    observer.observe(element);
  });
}

function animateOnLoad() {
  // console.log("finished loading");
  observeIntersection("animation__fade-initial", "animation__fade-final");
  observeIntersection(
    "animation__fade-initial-icon",
    "animation__fade-final-icon"
  );
}

export default function Meet() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/products") {
      animateOnLoad();
    }
  }, [location.pathname]);

  var docWidth = document.documentElement.offsetWidth;
  [].forEach.call(document.querySelectorAll("*"), function (el) {
    if (el.offsetWidth > docWidth) {
    }
  });

  return (
    <div className="body_meet">
      <section>
        <h2 className="purple-text header__font">Relayz Meet</h2>
        <h2>Introduction</h2>
        <p className="paragraphs">
          <br />
          In an era dominated by Web3, Relayz Meet stands as a testament to
          private and secure communication. Built upon the Relayz Protocol, it
          represents a paradigm shift from traditional centralized platforms,
          offering unmatched security, privacy, and flexibility in the
          ever-evolving digital landscape
        </p>
        <h2 className="purple-text header__font">Why Relayz Meet</h2>
        <h2>
          The Relayz Protocol
          <br />
          <br />
        </h2>
        <p className="paragraphs">
          A robust foundation of privacy-first, secure, and scalable
          decentralized communication infrastructure. Its core objective 
          is to ensure safe conversations and collaboration
          with seamless integration into dApps. <span className="purple-text">Relayz Meet</span> takes 
          pride in being the inaugural dApp under this protocol
        </p>
      </section>
      <article className="home-section__body--content">
        <div
          style={{ marginTop: "-2rem" }}
          className="home-description-content-box"
        >
          <div className="home-description-cta-text-body-right subHeader__font">
            <div
              style={{
                fontWeight: "400",
              }}
              className=" purple-text ext-right margin__bottom"
            >
              <h3>
                <br/>
                <br/>
                Uncompromised Privacy</h3>
            </div>
            <div className="animation__fade-initial">
              <p className="paragraphs">
                {" "}
                Dive into a world where you chat without fear. No downloads, no
                middlemen, no data trails. We uphold your privacy by ensuring no
                analytics, metadata, or any kind of data is stored
                {" "}
              </p>
            </div>
          </div>
          <div className="animation__fade-initial-icon">
          <br/>
          <br/>
          <br/>
            <img
              className="mobile-only"
              src={productImage01}
              alt="image2"
              style={{
                objectFit: "contain",
                height: "7.5rem",
              }}
            ></img>
            <img
              className="home-description-content-box-left"
              src={productImage01}
              alt="image3"
              style={{
                objectFit: "contain",
                minWidth: "10rem",
                height: "15vw",
                maxHeight: "8rem",
              }}
            ></img>
          </div>
        </div>
        <div className="home-description-content-box">
          <div className="animation__fade-initial-icon">
          <br/>
          
            <img
              className="home-description-content-box-right"
              src={productImage02}
              alt="image4"
              style={{
                objectFit: "contain",
                height: "15vw",
                maxHeight: "8rem",
              }}
            ></img>
          </div>
          <div className="home-description-cta-text-body-left subHeader__font ">
            <div
              style={{
                fontWeight: "400",
              }}
              className="purple-text text-left margin__bottom"
            >
              <h3>Unbreakable Security</h3>
            </div>
            <div className="animation__fade-initial">
              <p className="paragraphs">
                Experience genuine end-to-end encryption. We harness the power
                of blockchain to introduce ephemeral encryption keys. All your
                communication travels encrypted, safeguarded by the
                state-of-the-art Relayz decentralized node network, RelyNet
                <br/>
                <br/>
              </p>
            </div>
          </div>
          <div className="animation__fade-initial-icon">
            <img
              className="mobile-only"
              src={productImage02}
              alt="image5"
              style={{
                objectFit: "contain",
                height: "7.5rem",
                marginBottom: "3.5rem",
              }}
            ></img>
          </div>
        </div>
        <h2 className="purple-text header__font">
          Flawless Communication
          <br />
          <br />
        </h2>
        <div className="home-description-content-box">
          <div className="animation__fade-initial-icon">
            <img
              className="home-description-content-box-right"
              src={productImage04}
              alt="image8"
              style={{
                // minWidth: "10vw",
                height: "13rem",
                //marginLeft: 0,
              }}
            ></img>
          </div>
          <div className="home-description-cta-text-body-left subHeader__font">
            <div
              style={{
                fontWeight: "400",
              }}
              className="purple-text text-left margin__bottom"
            >
              <h3>Peer-to-peer Conferencing</h3>
            </div>
            <div className="animation__fade-initial">
            <p className="paragraphs">
              Utilize the power of Web-based P2P real-time communication via
              WebRTC
              <br/>
              <br/>
              </p>
            </div>
          </div>
          <div className="animation__fade-initial-icon">
            <img
              className="mobile-only"
              src={productImage04}
              alt="image9"
              style={{
                height: "11rem",
              }}
            ></img>
          </div>
        </div>
        <div style={{}} className="home-description-content-box">
          <div className="home-description-cta-text-body-right subHeader__font">
            <div
              style={{
                fontWeight: "400",
              }}
              className="purple-text text-right margin__bottom"
            >
              <h3>HD Quality Video Calls</h3>
            </div>
            <div className="animation__fade-initial">
            <p className="paragraphs">
              Immerse in HD quality video calls from any device, anywhere. The
              world is your meeting room
              <br/>
              <br/>
              </p>
            </div>
          </div>
          <div className="animation__fade-initial-icon">
            <img
              className="mobile-only"
              src={productImage05}
              alt="image10"
              style={{
                objectFit: "contain",
                height: "7.5rem",
              }}
            ></img>
            <img
              className="home-description-content-box-left"
              src={productImage05}
              alt="image11"
              style={{
                objectFit: "contain",
                minWidth: "10rem",
                height: "15vw",
                maxHeight: "8rem",
              }}
            ></img>
          </div>
        </div>
        <h2 className="purple-text header__font">
          Unmatched Versatility
          <br />
          <br />
        </h2>
        <div className="home-description-content-box">
          <div className="animation__fade-initial-icon">
            <img
              className="home-description-content-box-right"
              src={productImage06}
              alt="image12"
              style={{
                // minWidth: "10vw",
                height: "15vw",
                maxHeight: "8rem",
              }}
            ></img>
          </div>
          <div className="home-description-cta-text-body-left subHeader__font">
            <div
              style={{
                fontWeight: "400",
              }}
              className="purple-text text-left margin__bottom"
            >
              <h3>Multi-chain Protocol</h3>
            </div>
            <div className="animation__fade-initial">
            <p className="paragraphs">
              Relayz Meet is designed for the future. With EVM Compatible
              Layer-1 solutions, enjoy the scalability and power of the best
              Blockchains
              <br/>
              <br/>
              </p>
            </div>
          </div>
          <div className="animation__fade-initial-icon">
            <img
              className="mobile-only"
              src={productImage06}
              alt="image13"
              style={{
                height: "7.5rem",
                marginBottom: "3.5rem",
              }}
            ></img>
          </div>
        </div>
        <div className="home-description-content-box">
          <div className="home-description-cta-text-body-right subHeader__font">
            <div
              style={{
                fontWeight: "400",
              }}
              className="purple-text text-right margin__bottom"
            >
              <h3>Seamless Easy Access</h3>
            </div>
            <div className="animation__fade-initial">
            <p className="paragraphs">
              Your decentralized Web3 meeting experience is merely a click away
              <br/>
              <br/>
              </p>
            </div>

          </div>
          <div className="animation__fade-initial-icon">
            <img
              className="mobile-only"
              src={productImage07}
              alt="image14"
              style={{
                objectFit: "contain",
                height: "7.5rem",
              }}
            ></img>
            <img
              className="home-description-content-box-left"
              src={productImage07}
              alt="image15"
              style={{
                objectFit: "contain",
                minWidth: "10rem",
                height: "15vw",
                maxHeight: "8rem",
              }}
            ></img>
          </div>
        </div>
      </article>
      <article>
        <div className="home-section__hero--container">
          <div
            style={{
              fontWeight: "400",
              fontSize: "20px",
            }}
            className="text-center margin__bottom"
          >
            <h2>
              <span>Getting Started with </span>&nbsp;
              <span className="purple-text">Relayz Meet</span>
            </h2>
          </div>
          <article className="home-description-content-box-bottom">
            <div className="home-description-cta-text-body-right subHeader__font">
              <div
                style={{
                  fontWeight: "400",
                }}
                className="purple-text text-right margin__bottom"
              >
                <h3>Wallet Sign-in</h3>
              </div>
              <div className="animation__fade-initial">
              <p className="paragraphs">
                Easy integration with popular extension wallets like MetaMask
                <br/>
                <br/>
                </p>
              </div>
            </div>
            <div className="animation__fade-initial-icon">
              <img
                className="mobile-only"
                src={productImage09}
                alt="image16"
                style={{
                  objectFit: "contain",
                  height: "7.5rem",
                }}
              ></img>
              <img
                className="home-description-content-box-left"
                src={productImage09}
                alt="image17"
                style={{
                  objectFit: "contain",
                  minWidth: "10rem",
                  height: "15vw",
                  maxHeight: "8rem",
                }}
              ></img>
            </div>
          </article>
          <article className="home-description-content-box-bottom">
            <div className="home-description-cta-text-body-right subHeader__font">
              <div
                style={{
                  fontWeight: "400",
                }}
                className="purple-text text-right margin__bottom"
              >
                <h3>Quick Account Creation</h3>
              </div>
              <div className="animation__fade-initial">
              <p className="paragraphs">
                No on-chain wallet? No problem. We've got you covered. Quickly
                create an account, and we’ll set up a wallet for you in the
                background. It’s our way to bridge the gap between Web2 and Web3
                users
                <br/>
                <br/>
                </p>
              </div>
            </div>
            <div className="animation__fade-initial-icon">
              <img
                className="mobile-only"
                src={productImage10}
                alt="image18"
                style={{ height: "7.5rem" }}
              ></img>
              <img
                className="home-description-content-box-left"
                src={productImage10}
                alt="image19"
                style={{
                  objectFit: "contain",
                  minWidth: "10rem",
                  height: "15vw",
                  maxHeight: "8rem",
                }}
              ></img>
            </div>
          </article>
          <article className="home-description-content-box-bottom">
            <div className="home-description-cta-text-body-right subHeader__font">
              <div
                style={{
                  fontWeight: "400",
                }}
                className="purple-text text-right margin__bottom"
              >
                <h3>One-time Connection</h3>
              </div>
              <div className="animation__fade-initial">
              <p className="paragraphs">
                Connect your wallet once, and it's set for future meetings.
                Smart, efficient, and hassle-free
                <br/>
                <br/>
                </p>
              </div>
            </div>
            <div className="animation__fade-initial-icon">
              <img
                className="mobile-only"
                src={productImage11}
                alt="image20"
                style={{
                  objectFit: "contain",
                  height: "7.5rem",
                }}
              ></img>
              <img
                className="home-description-content-box-left"
                src={productImage11}
                alt="image21"
                style={{
                  objectFit: "contain",
                  minWidth: "10rem",
                  height: "15vw",
                  maxHeight: "8rem",
                }}
              ></img>
            </div>
          </article>
          <article className="home-description-content-box-bottom">
            <div className="home-description-cta-text-body-right subHeader__font">
              <div
                style={{
                  fontWeight: "400",
                }}
                className="purple-text text-right margin__bottom"
              >
                <h3>Craft Your Meeting Space</h3>
              </div>
              <div className="animation__fade-initial">
              <p className="paragraphs">
                Create private meeting rooms to invite participants and ensure a
                secure environment for your discussions
                <br/>
                <br/>
                </p>
              </div>
            </div>
            <div className="animation__fade-initial-icon">
              <img
                className="mobile-only"
                src={productImage12}
                alt="image22"
                style={{
                  objectFit: "contain",
                  height: "7.5rem",
                }}
              ></img>
              <img
                className="home-description-content-box-left"
                src={productImage12}
                alt="image23"
                style={{
                  objectFit: "contain",
                  minWidth: "10rem",
                  height: "15vw",
                  maxHeight: "8rem",
                }}
              ></img>
            </div>
          </article>
          <h2>
            <br />
            Dive into the future of private, secure, and decentralized
            communication. Dive into{" "}
            <a className="purple-text" href="https://meet.relayz.io">
              {" "}
              Relayz Meet
            </a>
          </h2>
        </div>
      </article>
    </div>
  );
}
