// External libraries and styles
import React, { useState, useEffect, useMemo, useRef } from "react";

// Internal modules, styles and assets
import { getAllNodes } from "../../../services/nodes";

import ManagementCard from "../../../components/managementCard";
import InvitationsList from "../../../components/invitationsList";
import NodeListItem from "../../../components/nodeRowItem";
import copyIcon from "../../../assets/icons/copy-icon.svg";
import shareIcon from "../../../assets/icons/share-icon.svg";
import searchIcon from "../../../assets/icons/search-icon.svg";

//Commented code - will need to uncomment to make it useful again

import useWindowDimensions from "../../../functions/useWindowDimensions";

import { useAuthContext } from "../../../contexts/AuthContext";
import "./index.sass";
import UpdateNodeModal from "../../../components/updateNodeModal";
import NodeUpdateWarning from "../../../components/nodeUpdateWarning";



export default function Profile() {
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [topRankingUsers, setTopRankingUsers] = useState(null);
  const { width } = useWindowDimensions();
  // eslint-disable-next-line 
  const { authenticatedUserData, isAwareOfNodeUpdate, isAdmin } = useAuthContext();
  // eslint-disable-next-line
  const [selectedInviteId, setSelectedInviteId] = useState(null);

  const handleInviteSelected = (id) => {
    setSelectedInviteId(id);
  };

  const [invitationsData, setInvitationsData] = useState([]);

  // sorting constants and states
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'
  const [currentSortParam, setCurrentSortParam] = useState({
    user: "",
    public: "",
  });
  const [listNodes, setListNodes] = useState({ user: [], public: [] });

  // created a fake logged in user to attempt node list splitting
  // const authenticatedUserOwnerId = authenticatedUserData.address
  const authenticatedUserOwnerId = authenticatedUserData.address;

  // const { ownerId, nodeId, traffic, bandwidth, region, meetingsProcessed } =
  //   selectedNode || {};

  const previousUserData = useRef();
  const [selectedTab, setSelectedTab] = useState("Reward Program");

  //handle sorting
  const sortNodesByParam = (listType, column) => {
    const direction =
      currentSortParam[listType] === column && sortDirection === "asc"
        ? "desc"
        : "asc";

    // Set the public or private list to sort elements
    const listToSort = listNodes[listType];

    const sortedNodes = [...listToSort]
      .sort((a, b) => {
        let result;
        switch (column) {
          case "traffic":
            result = parseInt(a.traffic) - parseInt(b.traffic);
            break;

          case "upload":
            result =
              (a.bandwidth && a.bandwidth.upload && a.bandwidth.upload.speed
                ? parseInt(a.bandwidth.upload.speed)
                : 0) -
              (b.bandwidth && b.bandwidth.upload && b.bandwidth.upload.speed
                ? parseInt(b.bandwidth.upload.speed)
                : 0);
            break;

          case "download":
            result =
              (a.bandwidth && a.bandwidth.download && a.bandwidth.download.speed
                ? parseInt(a.bandwidth.download.speed)
                : 0) -
              (b.bandwidth && b.bandwidth.download && b.bandwidth.download.speed
                ? parseInt(b.bandwidth.download.speed)
                : 0);
            break;

          default:
            result = a[column].localeCompare(b[column]);
        }

        return direction === "desc" ? -result : result;
      })
      .sort((a, b) => {
        const stateOrder =
          ["Active", "Inactive", "Disabled"].indexOf(a.state) -
          ["Active", "Inactive", "Disabled"].indexOf(b.state);
        if (stateOrder !== 0) return stateOrder;
        return a.state.localeCompare(b.state);
      });

    setListNodes((prevState) => ({
      ...prevState,
      [listType]: sortedNodes,
    }));

    setSortDirection(direction);
  };

  const truncateOwnerId = (ownerId) => {
    if (ownerId.length > 25) {
      return ownerId.slice(0, 10) + "..." + ownerId.slice(-10);
    }
    return ownerId;
  };

  const handleChangeSortParam = (listType, param) => {
    setCurrentSortParam((prevState) => ({
      ...prevState,
      [listType]: param,
    }));
    sortNodesByParam(listType, param);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllNodes();
        if (!Array.isArray(data)) throw new Error("Invalid response");

        let userMatchingNode = null;

        let validNodes = data
          .flatMap((nodeItem) => {
            if (!nodeItem.ownerId) {
              return []; // Retuns an empty array to remove the node when it doesn't have a ownerId
            }

            if (
              !nodeItem.traffic ||
              nodeItem.traffic === "0" ||
              nodeItem.traffic === ""
            ) {
              if (
                !nodeItem.region ||
                nodeItem.region === "-" ||
                nodeItem.region === ""
              ) {
                return [
                  {
                    ...nodeItem,
                    state: "Disabled",
                  },
                ];
              }
            }
            return [nodeItem]; // If the node item has no traffic and no region, it's state is set as disabled
          })
          .sort((a, b) => {
            // 1. Online/Offline Status:
            const stateOrder =
              ["Active", "Inactive", "Disabled"].indexOf(a.state) -
              ["Active", "Inactive", "Disabled"].indexOf(b.state);
            if (stateOrder !== 0) return stateOrder;

            // 2. Traffic:
            const trafficDiff = parseInt(b.traffic) - parseInt(a.traffic);
            if (trafficDiff !== 0) return trafficDiff;

            // 3. Alphabetic Order:
            return a.ownerId.localeCompare(b.ownerId);
          });

        setTopRankingUsers({
          [validNodes[0].traffic]: "1",
          [validNodes[1].traffic]: "2",
          [validNodes[2].traffic]: "3",
        });

        const updatedValidNodes = validNodes.map((node) => ({
          ...node,
          ownerId: truncateOwnerId(node.ownerId),
        }));

        validNodes = updatedValidNodes;

        if (authenticatedUserData && authenticatedUserData.address) {
          userMatchingNode = validNodes.find(
            (node) => node.ownerId === authenticatedUserData.address
          );
          if (userMatchingNode) {
            validNodes.unshift(
              validNodes.splice(validNodes.indexOf(userMatchingNode), 1)[0]
            );
          }
        }

        if (
          JSON.stringify(authenticatedUserData) !==
          JSON.stringify(previousUserData.current)
        ) {
          previousUserData.current = authenticatedUserData;

          setNodes((prevNodes) => {
            let updatedNodes = validNodes;
            return updatedNodes;
          });
          setSelectedNode(
            (prevSelectedNode) => userMatchingNode || validNodes[0]
          );
        }
      } catch (e) {
        console.error("Failed to fetch nodes:", e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredNodes = useMemo(
    () =>
      nodes.filter(({ ownerId, nodeId }) =>
        ownerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        nodeId.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [nodes, searchTerm]
  );


  const [userNodes, publicNodes] = useMemo(
    () =>
      filteredNodes.reduce(
        (acc, list) => {
          if (list.ownerId === authenticatedUserOwnerId) {
            acc[0].push(list); // userNodes
          } else {
            if (list.state !== "Disabled") {
              // If node item has disabled state, then it will be removed from the public list
              acc[1].push(list); // publicNodes
            }
          }
          return acc;
          // eslint-disable-next-line
        },
        [[], []]
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredNodes]
  );

  const filteredByState = (state) =>
    nodes.filter((n) => n.state === state).length;

  // const renderInfo = (title, value) => (
  //   <div className="management_card-row">
  //     <div>{title}</div>
  //     <div>{value !== undefined ? value : "N/A"}</div>
  //   </div>
  // );

  const renderTableHeader = (isPublic = true) => (
    <>
      {isPublic ? (
        <h2 className="node_table-title">Public</h2>
      ) : (
        <h2 className="node_table-title">My nodes</h2>
      )}
      <div className="node_table-header">
        <div className="node_table-header-unit">Wallet</div>
        <div
          className={`node_table-header-unit ${width <= 420 ? "node_table-header--no-mobile" : ""
            }`}
        >
          Status
        </div>
        <div
          className={`node_table-header-unit ${width <= 420 ? "node_table-header--no-mobile" : ""
            }`}
        >
          Node ID
        </div>
        <div className="node_table-header-unit">Region</div>
        <div className="node_table-header-unit node_table-header--no-mobile">
          <button
            className="node_table-header-btn-filter"
            title="Filter by Upload Speed"
            onClick={() =>
              handleChangeSortParam(isPublic ? "public" : "user", "upload")
            }
          >
            <span>Upload Speed</span>
          </button>
        </div>
        {/* Use width to remove this div on screens equal or smaller than 620px */}
        <div
          className={`node_table-header-unit ${width <= 620 ? "node_table-header--no-mobile" : ""
            }`}
        >
          <button
            className="node_table-header-btn-filter"
            title="Filter by Download Speed"
            onClick={() =>
              handleChangeSortParam(isPublic ? "public" : "user", "download")
            }
          >
            <span>Download Speed</span>
          </button>
        </div>
        <div className="node_table-header-unit">
          <button
            className="node_table-header-btn-filter"
            title="Filter by Traffic"
            onClick={() =>
              handleChangeSortParam(isPublic ? "public" : "user", "traffic")
            }
          >
            <span>Traffic</span>
          </button>
        </div>
        {!isPublic && (
          <>
            <div className="table-actions node_table-header--no-mobile">
              Actions
            </div>
          </>
        )}
      </div>
    </>
  );

  // Retrieve the referral code
  const referralCode = authenticatedUserData.address;
  // Function to copy the referral code to clipboard

  // Function to copy the referral code to clipboard
  const handleCopyReferralCode = () => {
    const baseUrl = "https://relayz.io/welcome/";
    navigator.clipboard
      .writeText(baseUrl + referralCode)
      .then(() => {
        alert("Referral link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy referral link: ", err);
        alert("Failed to copy referral link. Please try again.");
      });
  };

  // Function to share the referral code
  const handleShareReferralCode = () => {
    const baseUrl = "https://relayz.io/welcome/";

    // Constructing the formal message
    const title = `${referralCode} Invites You to the Relayz Referral Program`;
    const bodyText = `${referralCode} wants you to join the Relayz Referral Program.
    Dive in now using the link below and embark on your journey to earn RELY tokens:`;
    // Check if the Web Share API is available
    if (navigator.share) {
      navigator
        .share({
          title: title,
          text: bodyText.replace("%0A", "\n"), // Convert encoded line break back to a newline for the Web Share API
          url: baseUrl + referralCode,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error(
            "Something went wrong sharing the referral code",
            error
          );
        });
    } else {
      // Fallback in case the Web Share API is not available
      // Use a mailto link as fallback
      const subject = encodeURIComponent(title);
      const body = encodeURIComponent(
        `${bodyText}\n\n${baseUrl + referralCode}`
      );
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    }
  };

  useEffect(() => {
    if (!currentSortParam["public"] && !currentSortParam["user"]) {
      setListNodes({ user: userNodes, public: publicNodes });
    }
  }, [currentSortParam, publicNodes, userNodes]);

  // loading dynamic data from api

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.relayz.io/api/user/referrals/${authenticatedUserData.address}`);
        //const response = await fetch(`https://api.relayz.io/api/user/referrals/zani.testnet`);

        if (!response.ok) {
          console.error("API returned with status:", response.status);
          return;
        }

        const data = await response.json();
        // console.log("Raw API Data:", data);
        if (data.success && Array.isArray(data.data) && data.data.length > 0) {
          const transformedData = data.data.map((item) => ({
            id: item._id || "",
            referredWallet: item.accountId || "",
            level: 1,
            createdAt: item.createdAt || "",
            status:
              item.publicKey && item.referredBy && item.accountId
                ? "complete"
                : "complete",
          }));

          // console.log("Transformed Data:", transformedData); // Log it here
          setInvitationsData(transformedData);
        } else {
          setInvitationsData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authenticatedUserData.address]);

  return (
    <>
      <div className="body_nodes">
        <div className="header_nodes">
          <div className="management_main-card">
            <div className="management_main-card-info">
              <ManagementCard
                selectedNode={selectedNode}
                selectedTab={selectedTab}
                invitationsData={invitationsData}
                isAdminUser={isAdmin}
              // selectedInviteId={selectedInviteId}
              />
            </div>
            <div className="vl"></div>
            <div className="management_main-card-info-toolbar">
              <div className="management_main-card-title">Referral Program</div>
              <h2 className="purple-text">Your referral code is</h2>
              <div className="management_main-card-row">
                <div className="network__sections-referral-buttons">
                  <span className="network__sections-profile-referral-code">
                    {truncateOwnerId(authenticatedUserData.address)}{" "}
                  </span>
                  <div className="management__referral-section">
                    <button
                      className="network__sections-referral-buttons--copy"
                      type="button"
                      onClick={() => handleCopyReferralCode()}
                    >
                      <span>Copy</span>
                      <img src={copyIcon} alt="Document on document icon" />
                    </button>
                    <button
                      className="network__sections-referral-buttons--share"
                      type="button"
                      onClick={() => handleShareReferralCode()}
                    >
                      <span>Share</span>
                      <img src={shareIcon} alt="Arrow up inside square icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profile_selector">
            <div className="relayz-card-titles">
              <h2
                className={selectedTab === "Reward Program" ? "active" : ""}
                onClick={() => setSelectedTab("Reward Program")}
              >
                Reward Program
              </h2>
              <h2
                className={selectedTab === "Node Management" ? "active" : ""}
                onClick={() => setSelectedTab("Node Management")}
              >
                Node Management
              </h2>
            </div>
          </div>

          {selectedTab === "Node Management" && (
            <div>
              {/* Contents for Node Management tab */}

              <div className="management_node-search">
                <h1>
                  <br />
                  Total Nodes ({nodes.length})
                  <br /> Active ({filteredByState("Active")}) Inactive (
                  {filteredByState("Inactive")}) Disabled (
                  {filteredByState("Disabled")})
                </h1>
                <span className="management_node-search-input">
                  <input
                    className="management_node-search-input-field"
                    type="text"
                    placeholder="Search by Wallet ID..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      if (
                        currentSortParam["public"] !== "" ||
                        currentSortParam["user"] !== ""
                      ) {
                        setListNodes({ user: userNodes, public: publicNodes });
                      }
                      setCurrentSortParam({ user: "", public: "" });
                    }}
                  />
                  <button type="button">
                    <img src={searchIcon} alt="Magnifying glass" />
                  </button>
                </span>
              </div>

              {/* Render warning update message */}
              {/* <NodeUpdateWarning>
                <p>
                  New Relayz Node Update Available <span>Click Here to Know More</span>
                </p>
              </NodeUpdateWarning> */}

              <NodeUpdateWarning className="margin__top">
                <p>
                Registration for new Nodes suspended.
                </p>
              </NodeUpdateWarning>

              {/* Render the user's list */}
              {listNodes["user"].length > 0 && (
                <>
                  {renderTableHeader(false)}
                  <div className="node_table-list node_table-list--private">
                    {listNodes["user"].map((node) => (
                      <NodeListItem
                        key={node.nodeId}
                        node={node}
                        getCurrentNode={setSelectedNode}
                        selectedNode={selectedNode?.nodeId === node.nodeId}
                        width={width}
                        userData={authenticatedUserData}
                        isNodeOwner={node.ownerId === authenticatedUserOwnerId}
                        rankingPosition={topRankingUsers[node.traffic] || ""}
                        isAdminUser={isAdmin}
                      />
                    ))}
                  </div>
                </>
              )}

              {/* Render the public list */}
              {listNodes["public"].length > 0 && (
                <>
                  {renderTableHeader(true)}
                  <div className="node_table-list">
                    {listNodes["public"].map((node) => (
                      <NodeListItem
                        key={node.nodeId}
                        node={node}
                        getCurrentNode={setSelectedNode}
                        selectedNode={selectedNode?.nodeId === node.nodeId}
                        width={width}
                        userData={authenticatedUserData}
                        isNodeOwner={false}
                        rankingPosition={topRankingUsers[node.traffic] || ""}
                        isAdminUser={isAdmin}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {selectedTab === "Reward Program" && (
            <div>
              {/* Reward program component - requires invitations list as prop */}
              <InvitationsList
                list={invitationsData}
                onInviteSelected={handleInviteSelected}
              />
            </div>
          )}
        </div>
      </div>
      {/* Show update node modal */}
      {
        selectedTab === "Node Management" && !isAwareOfNodeUpdate && <UpdateNodeModal />
      }
    </>
  );
}
