export default function useGetSocial() {

    const context = require.context("../assets/images/social", false, /.svg$/);
    const socialResources = [];
    const urls = [
      'https://x.com/RelayzOfficial',
      'https://discord.com/invite/DuEJEMss9G',
      'https://t.me/RelayzOfficial',
      'https://relayz.medium.com',
      'https://www.linkedin.com/company/relayz',
    ];
  
    context.keys().forEach((key, index) => {
      const fileName = key.replace('./', '');
      const resource = require(`../assets/images/social/${fileName}`);
      socialResources.push({
        image: resource,
        url: urls[index]
      });

    });
    return socialResources;
}


