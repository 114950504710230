import "./index.sass";
import Footer from "../../components/Footer";
import PageContainer from "../../components/pageContainer";

export default function Privacy() {

  return (
    <PageContainer>
      <section className="privacy-relayz" id="relayz privacy">
        <h4
          className="large-title purple-text header__font"
        >
          Relayz Privacy
        </h4>
        <div className="privacy__box home-description-cta-text">
          <h2 className="privacy__box-text-spacing">Privacy Policy for Relayz Ltd</h2>
          <p className="privacy__box-text-spacing">
            At Relayz Ltd, we are genuinely committed to protecting the privacy
            and security of our users. This Privacy Policy explains how we
            collect, use, and disclose information when you use our website,
            network and apps in compliance with GDPR.
          </p>
          <h3 className="privacy__box-text-spacing" >Information We Collect</h3>
          <p className="privacy__box-text-spacing">
            When you visit our website, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse our website,
            we collect information about the individual web pages or products
            that you view, what websites or search terms referred you to our
            website, and information about how you interact with our website. We
            collect this information using cookies, which are small data files
            that are placed on your device when you visit our website. Cookies
            help us to personalize your experience on our website and to improve
            our services. We also collect web3 wallet IDs when you authenticate
            with us to login.
          </p>
          <h3 className="privacy__box-text-spacing">Use of Information</h3>
          <p className="privacy__box-text-spacing">
            We use the information primarily to secure our network and users. We
            also use the information that we collect to improve and optimize our
            website, for example by generating analytics about how our users
            browse and interact with our website and apps.
          </p>
          <h3 className="privacy__box-text-spacing">Storage of Information</h3>
          <p className="privacy__box-text-spacing">
            We store log files for the minimum amount of time strictly needed,
            typically only 30 days.
          </p>
          <h3 className="privacy__box-text-spacing">Disclosure of Information</h3>
          <p className="privacy__box-text-spacing">
            We may disclose your personal information if we are required to do
            so by a court of law or if you violate our Terms of Service.
          </p>
          <h3 className="privacy__box-text-spacing">Data Security</h3>
          <p className="privacy__box-text-spacing">
            We take the security of your personal information seriously and we
            implement appropriate technical and organizational measures to
            protect it from unauthorized access, alteration, disclosure, or
            destruction.
          </p>
          <h3 className="privacy__box-text-spacing">Your Rights</h3>
          <p className="privacy__box-text-spacing">
            Under the GDPR, you have the right to request access to,
            rectification, or erasure of your personal data, as well as the
            right to restrict or object to the processing of your personal data.
            You can exercise these rights by contacting us at privacy@relayz.io
          </p>
          <h3 className="privacy__box-text-spacing">Changes to this Policy</h3>
          <p className="privacy__box-text-spacing">
            We may update this Privacy Policy from time to time in order to
            reflect changes to our practices or for other operational, legal or
            regulatory reasons.
          </p>
          <h3 className="privacy__box-text-spacing">Contact Us</h3>
          <p className="privacy__box-text-spacing">
            If you have any questions or concerns about our Privacy Policy,
            please contact us at privacy@relayz.io
          </p>
        </div>
      </section>
      
      <Footer />
    </PageContainer>
  );
}
