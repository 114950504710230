import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';  // Using the useNavigate hook

const Disclaimer = ({ children }) => {
  const [accepted, setAccepted] = useState(false);
//  const navigate = useNavigate();  // Replacing useHistory with useNavigate


  const handleAccept = () => {
    setAccepted(true);
    // Removed the navigate function here since you don't want to redirect
      //   navigate('/*');  // Replacing history.push with navigate
  };

  if (accepted) {
    return children;
  }

  return (
    <div className="modal">
      <div className="modal__box">
        <p>We've updated our <a className="purple-text footer-link" href="/terms" target='_blank'>Terms of use</a> and our <a href="/privacy" target='_blank' className="footer-link">Privacy Policy</a> with important information about how we use your data and your privacy options.</p>
        <div className="modal__buttons--centered">
          <button className="modal-button" onClick={handleAccept}>I understand</button>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
