// external imports
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

// local imports
import { fadeInAnimation } from '../../functions/animations';

export default function PauseNodesModal({ handlePauseNodesModal }) {
  const navigate = useNavigate();

  const handleKnowMore = () => {
    handlePauseNodesModal(false);
    navigate('/nodedownloadwarningpage');
  };

  const handleUnderstand = () => {
    handlePauseNodesModal(false);
  }

  return (
    <div className="modal">
      <motion.div
        className="modal__box"
        initial="hidden"
        animate="visible"
        variants={fadeInAnimation}
      >
        <h2>Registration for new Nodes suspended </h2>
        <p>
          We are temporarily halting the registration of new nodes as we address certain issues.
          <br />
          <br />
          Please keep an eye on our website for further updates.

        </p>
        <div className="modal__buttons margin__top">
          <button className='modal-button' onClick={handleKnowMore}>Know More</button>
          <button className='modal-button' onClick={handleUnderstand}>I understand</button>
        </div>
      </motion.div>
    </div>
  );
}
