import "./index.sass";

// External imports
import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";

// Styles
import "./index.sass";

// Assets
import nearLogo from "../../assets/images/web3/near-logo.png";
import metamaskLogo from "../../assets/images/web3/metamask-logo.png";


// Components and utilities
import Footer from "../../components/Footer";
import { fadeUpAnimation } from "../../functions/animations";
import StoreContext from "../../store/RootStore";
import { useAuthContext } from "../../contexts/AuthContext";
import PageContainer from "../../components/pageContainer";

export default function Invite() {
  // Context and React Router hooks
  const { FunctionStore } = StoreContext();
  const storedReferralId = localStorage.getItem("referralId");

  const {
    isAuthenticated,
    authenticatedUserData: userData,
    metamaskLogin,
  } = useAuthContext();
  const navigate = useNavigate();

  // scroll to function div ids


  // const { nearLogin } = useAuthContext();

  // UI State management



  const [isNearButtonActive] = useState(true);
  const [isMetaButtonActive] = useState(true);

 
  const [errorMessage, setErrorMessage] = useState("");

  const handleScrollToId = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };




  // Handle Near Login
  const handleNearLogin = async () => {
   

    try {
      await FunctionStore.loginWithNear();
    } catch (error) {
      console.error("Metamask login failed:", error);
    }
  };

  const handleMetamaskLogin = async () => {


    try {
      await metamaskLogin();
    } catch (error) {
      console.error("Metamask login failed:", error);
    }
  };

  // Retrieve the referral code
  const referralCode = isAuthenticated ? userData.address : undefined;

  // Function to copy the referral code to clipboard
  const handleCopyReferralCode = () => {
    const baseUrl = "https://relayz.io/welcome/";
    navigator.clipboard
      .writeText(baseUrl + referralCode)
      .then(() => {
        alert("Referral link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy referral link: ", err);
        alert("Failed to copy referral link. Please try again.");
      });
  };

 

  // Render function for wallet login button
  const renderButton = (onClick, src, alt, active) => (
    <button
      className="image-button wallet_connection-item-button"
      onClick={onClick}
      disabled={!active}
    >
      <img src={src} alt={alt} />
    </button>
  );

  const handleFormSubmit = async () => {
    handleCopyReferralCode();
    setErrorMessage("");

    const commonData = {
      name: "",
      referredBy: storedReferralId || "",
      loginType: userData?.loginType,
    };

    let url;
    let data;

    if (userData?.loginType === "near") {
      url = "https://api.relayz.io/api/auth/referral/near/login";
      data = {
        ...commonData,
        nearWalletId: userData?.address,
        publicKey: userData?.publicKey,
      };
    } else if (userData?.loginType === "metamask") {
      // Send POST to /api/user first when loginType is metamask
      const userResponse = await fetch("https://api.relayz.io/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accountId: userData?.address }),
      });

      if (!userResponse.ok) {
        throw new Error(`HTTP error! Status: ${userResponse.status}`);
      }

      url = "https://api.relayz.io/api/auth/referral/metamask/login";
      data = {
        ...commonData,
        metamaskAddress: userData?.address,
      };
    } else {
      console.error("Unsupported loginType");
      setErrorMessage(
        "There was an issue with the request due to unsupported login type."
      );
      return;
    }

    if (
      (data.loginType === "near" && (!data.nearWalletId || !data.publicKey)) ||
      (data.loginType === "metamask" && !data.metamaskAddress)
    ) {
      console.error("There was an issue with the request data.");
      setErrorMessage("There was an issue with the request data.");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      localStorage.removeItem("referralId");
      // eslint-disable-next-line
      const result = await response.json();
      // setShowThankYou(true);
      navigate("/nodemanagement");
    } catch (error) {
      console.error("There was an issue with the POST request:", error);
      setErrorMessage("There was an issue with the request.");
    }
  };




  return (
    <PageContainer>
      <h2 className="purple-text header__font">
        Join the Relayz Invite Program
      </h2>
      <h2 className="network__article">
        We value our community and wish to reward our members for their active
        participation and dedication.
        <br />
        <br />
        Our invite program offers members the opportunity to earn rewards in
        various ways. The more people you invite the higher the rewards, the
        more engaged you and invited people are in Relayz the higher the rewards
        e.g. the more{" "}
        <a className="purple-text" href="https://meet.relayz.io" target="blank">
          Relayz Meet{" "}
        </a>
        calls the higher the rewards.
      </h2>
      <br />
      <h2 className="purple-text header__font network__central">
        First sign in with your wallet
      </h2>
      <div>
        <motion.section
          className="welcome"
          initial="hidden"
          animate="visible"
          variants={fadeUpAnimation}
        >
          
            <div className="welcome__section-invite">
              <div className="welcome__section-steps">
                <motion.div className="welcome__section-item">
                  <br />
                  {storedReferralId && (
              <h2 className="welcome__subtitle">
                You have been invited by <span className="purple-text">{storedReferralId}</span>
                <br />
                <br />   Please follow the instructions to complete your
                registration.
              </h2>
            )}
                  <div className="near-section">
                    {renderButton(
                      handleMetamaskLogin,
                      metamaskLogo,
                      "metamask",
                      isMetaButtonActive
                    )}
                    {renderButton(
                      handleNearLogin,
                      nearLogo,
                      "near",
                      isNearButtonActive
                    )}

                    {isAuthenticated ? (
                      <div id="nearSucess" className="near-section-success">
                        <p>Wallet logged in successfully</p>
                      </div>
                    ) : (
                      <div id="nearFail" className="near-section-success">
                        <p>Click on the wallet logo to join</p>
                      </div>
                    )}
                  </div>
                  <small className="welcome__section-error-log">
                    {errorMessage}
                  </small>
                  <div className="welcome__section-steps-buttons">
                    <button
                      className=" welcome__section-steps-next"
                      onClick={handleFormSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </motion.div>
              </div>
              <div className="welcome__section-item">
                <h2 className=" welcome__section-title-invite">
                  Step-by-step guide to join and start inviting
                </h2>

                <div className="welcome__section-purpose">
                  <ul className="welcome__section-purpose-list">
                    <li
                      onClick={() => handleScrollToId("div1")}
                      className="welcome__section-purpose-item"
                    >
                      <span className="welcome__section-purpose-text">
                        1) Sign in with your wallet. If you don't have one,
                        follow the instructions to create one.
                      </span>
                    </li>
                    <li
                      onClick={() => handleScrollToId("div2")}
                      className="welcome__section-purpose-item"
                    >
                      <span className="welcome__section-purpose-text">
                        2) After signing in, click 'submit'. (Nickname field is
                        optional.)
                      </span>
                    </li>
                    <li
                      onClick={() => handleScrollToId("div3")}
                      className="welcome__section-purpose-item"
                    >
                      <span className="welcome__section-purpose-text">
                        3) Invite link will automatically be copied to your
                        clipboard. and you re-directed to the authenticated
                        area.
                      </span>
                    </li>
                    <li
                      onClick={() => handleScrollToId("div4")}
                      className="welcome__section-purpose-item"
                    >
                      <span className="welcome__section-purpose-text-last-child">
                        Referral code and link for the program are always
                        available on your profile page.
                      </span>
                    </li>
                    <li className="welcome__section-purpose-item"></li>
                  </ul>
                </div>
              </div>
            </div>
          
        </motion.section>
      </div>

      {/* should invert the order those infos show up */}

      <div className="network__article network__sections">
        <div>
          <h2>
            <div className="purple-text header__font network__central">
              Relayz Meet Rewards
            </div>
            The referral system rewards you for every referral of an active user
            of Relayz Meet.
            <br />
            <strong>
              <br />
              Rewards are calculated by:
              <br />
            </strong>
            <br />
            1) Doing Relayz Meet calls - frequency of the calls you and your
            invited users make, duration of the calls, number of people on the
            calls
            <br />
            <br />
            2) Joining{" "}
            <a
              target="blank"
              href="https://twitter.com/RelayzOfficial"
              className="purple-text"
            >
              Relayz Twitter
            </a>
            <br />
            <br />
            3) Joining{" "}
            <a
              target="blank"
              href="https://discord.com/invite/DuEJEMss9G"
              className="purple-text"
            >
              Relayz Discord
            </a>
            <br />
            <br />
            4) Joining{" "}
            <a
              target="blank"
              href="https://t.me/RelayzOfficial"
              className="purple-text"
            >
              Relayz Telegram
            </a>
            <br />
            <br />
            <strong>
              All the above have an impact on the calculation of rewards, the
              more active you are by doing Relayz Meet calls the higher the
              rewards.
              <br />
              <br />
              Joining Relayz socials gives extra rewards.
            </strong>
            <br />
            <br />
            <div className="purple-text header__font network__central">
              RelyNet Node Rewards
            </div>
            The referral system rewards you for every referral of an active node
            operator on RelyNet.
            <br />
            <strong>
              <br />
              Rewards are calculated by:
              <br />
            </strong>
            <br />
            1) When an invited node operator signs up with your referral code
            and begins running nodes on RelyNet over a minimum period of 3
            months and up to 6 months, you will receive monthly rewards.
            <br />
            <br />
            2) Joining{" "}
            <a
              target="blank"
              href="https://twitter.com/RelayzOfficial"
              className="purple-text"
            >
              Relayz Twitter
            </a>
            <br />
            <br />
            3) Joining{" "}
            <a
              target="blank"
              href="https://discord.com/invite/DuEJEMss9G"
              className="purple-text"
            >
              Relayz Discord
            </a>
            <br />
            <br />
            4) Joining{" "}
            <a
              target="blank"
              href="https://t.me/RelayzOfficial"
              className="purple-text"
            >
              Relayz Telegram
            </a>
            <br />
            <br />
            <strong>
              All the above have an impact on the calculation of rewards.
              <br />
              <br />
              Joining Relayz socials gives extra rewards.
            </strong>
            <br />
            <br />
            <div className="purple-text header__font network__central">
              Eligibility & Criteria
            </div>
            To begin earning rewards, members must first register with their
            wallet.
            <br />
            <br />
            Only one wallet registration is allowed per individual. Multiple
            registrations using different wallets can lead to disqualification
            from the Rewards Program.
            <br />
            <br />
            Participation is defined by attending, initiating, or actively
            contributing to meetings. Just creating an account without active
            participation will not be eligible for rewards.
            <br />
            <br />
            <p className="purple-text">Sustained Activity Higher Rewards</p>
            To ensure that our members are genuinely engaged, higher rewards
            will be given to those who maintain consistent Relayz Meet activity
            for at least 30 consecutive days
            <br />
            <br />
            <p className="purple-text">Node Registration and Operation</p>
            Members who register and successfully operate a Node will be
            eligible for additional rewards.
            <br />
            Detailed terms and conditions regarding Node operation, maintenance,
            and associated rewards will be provided in the{" "}
            <Link
              to="/network/incentive%20program"
              target="blank"
              className="purple-text"
            >
              {" "}
              Nodes Incentive Program page.
            </Link>
            <br />
            <br />
            <div className="purple-text header__font network__central">
              General Terms
            </div>
            Relayz reserves the right to modify, change, or discontinue the
            Rewards Program at any time without prior notice.
            <br />
            <br />
            Any form of fraudulent activity, manipulation, or unethical
            practices can lead to immediate disqualification from the Rewards
            Program and potential suspension from Relayz.
            <br />
            <br />
            Rewards earned do not have a monetary value and cannot be exchanged
            for cash. The rewards can only be used within the Relayz ecosystem.
            <br />
            <br />
            Relayz's decision regarding eligibility, awarding of rewards, and
            any disputes will be final and binding.
            <br />
            <br />
          </h2>
        </div>
      </div>

      {/* should invert the order those infos show up */}

      <Footer />
    </PageContainer>
  );
}
