/* eslint-disable jsx-a11y/anchor-is-valid */
import "./index.sass";
import React, { useState } from "react";
import bug from "../../../assets/images/bugop.svg";
import news from "../../../assets/images/newsop.svg";
import nodes from "../../../assets/images/nodesop.svg";
import warningIcon from "../../../assets/icons/exclamation-circle.svg";
import PauseNodesModal from "../../../components/pauseNodesModal";
import NodeUpdateWarning from "../../../components/nodeUpdateWarning";

//eslint-disable-next-line
function DownloadLink({ href, children }) {
  return (
    <div>
      <a className="purple-text" href={process.env.PUBLIC_URL + href}>
        {children}
      </a>
      <br />
    </div>
  );
}

const renderNodeUpdateWarning = () => (
  <div className="warning_notification">
    <img src={warningIcon} alt="Exclamation mark circle" />
    <p>Now Accessible to All External Operators</p>
  </div>
);

export default function Testnet() {
  const [showPauseNodesModal, setShowPauseNodesModal] = useState(true);

  const handlePauseNodesModal = () => {
    setShowPauseNodesModal(false);
  };

  return (
    <>
      <div className="network__article network__sections">
        <h2 className="purple-text header__font" id="testnet">
          RelyNet Testnet
        </h2>

        <div>
          {renderNodeUpdateWarning()}
          <br />
          <h2>
            What is the RelyNet
            <br />
            <br />
          </h2>
          <p className="paragraphs">
            At the heart of decentralized innovations, stands RelyNet - our
            cutting-edge platform dedicated to ensuring robust, transparent, and
            efficient decentralized operations. The RelyNet Testnet is a pivotal
            component of this ecosystem, designed as a sandbox environment where
            we birth and nurture new features. Initially debuted on the RelyNet
            internal devnet, these features, once refined, make their way to the
            testnet. This allows for an enriching collaborative testing
            experience alongside our dedicated Relayz community.
          </p>

          <h2>
            <br />
            The Wait is over
            <br />
            <br />
          </h2>
          <p className="paragraphs">
            We're ecstatic to unveil the first version of the RelyNet Testnet.
            This monumental step symbolizes not just our technological
            advancement but also our commitment to a decentralized future.
            Although it's still in its nascent stage, our vision remains clear:
            to progressively improve the testnet, paving the way for a seamless
            transition to the mainnet. What drives us? Our unwavering ambition
            to bolster the stability and reliability of the RelyNet Testnet.
          </p>
          <h2>
            <br />
            <br />
            Be part of the Relyz and helo enhancing the stability and
            reliability of the RelyNet Testnet.
            <br />
            <br />
            <br />
          </h2>

          <div className="items_center">
            <div>
              <h2>
                <img
                  className="ilustration__small"
                  src={bug}
                  alt="find bugs ilustration"
                />{" "}
                <br /> <br />
                Help us to find Bugs
              </h2>
            </div>
            <div>
              <h2>
                <img
                  className="ilustration__small"
                  src={news}
                  alt="new features ilustration"
                />{" "}
                <br />
                <br />
                Gain Early Access to Innovative Features
              </h2>
            </div>
            <div>
              <h2>
                <img
                  className="ilustration__small"
                  src={nodes}
                  alt="node operators ilustration"
                />{" "}
                <br />
                <br />
                Become a Node Operator
              </h2>
            </div>
          </div>
        </div>
        <div>
          <br />
          <br />
          <div>
            <h2 className="purple-text header__font network__central">
              RelyNet Testnet Node
            </h2>
          </div>
          <div className="horizontal">
            <div className="">
              <p className="paragraphs">
                We're excited to announce that the RelyNet Node is now available
                for download through our Testnet!
                <br />
                To get started, simply use the shortcut below to download the
                RelyNet Node directly.
                <br />
                <br />
                Note that installation instructions and minimum requirements are
                available on our separate{" "}
                <a className="purple-text" href="/network/downloads">
                  {" "}
                  downloads{" "}
                </a>
                page.
                <br /> <br />
                You can skip that step and initiate the download right away
                using the shortcut below.
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>

          <div>
            <div>
              <div>
                <div>
                  <h2 className="purple-text header__font downloads__central">
                    Testnet Node Downloads
                  </h2>
                  <div className="downloads__wrapper">

              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/x86_64-prod-linux-node-cli.zip"}>
                    <p>X86</p>
                  </a>
                  {/* <a aria-label="button" >
                    <p>X86</p>
                  </a> */}
                </div>
                <DownloadLink href="/resources/files/binaries/x86_64-prod-linux-node-cli.zip">
                  <p className="paragraphs">Linux Download (X86 and X64)</p>
                </DownloadLink>
                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (X86 and X64)</a>
                </div> */}
              </div>


              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/aarch64-prod-linux-node-cli.zip"}>
                    <p>ARM</p>
                  </a>
                  {/* <a aria-label="button">
                    <p>ARM</p>
                  </a> */}
                </div>
                <DownloadLink href="/resources/files/binaries/aarch64-prod-linux-node-cli.zip">
                  <p className="paragraphs">Linux Download (ARM Processors)</p>
                </DownloadLink>

                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (ARM Processors)</a>
                </div> */}
              </div>


              <div className="options__circle--wrapper" onClick={() => setShowPauseNodesModal(true)}>
                <div className="instructions__option clickable">
                  <a href={process.env.PUBLIC_URL + "/resources/files/binaries/vm_1.2.ova"}>
                    <p>VM</p>
                  </a>
                  {/* <a aria-label="button" >
                    <p>VM</p>
                  </a> */}
                </div>

                <DownloadLink href="/resources/files/binaries/vm_1.2.ova">
                  <p className="paragraphs">Linux Download (Virtual Machine)</p>
                </DownloadLink>

                {/* <div>
                  <a className="paragraphs purple-text">Linux Download (Virtual Machine)</a>
                </div> */}

              </div>
            </div>
                </div>
              </div>

              <div>
                <h2>
                  <br />
                  <br />
                  What is a Node Operator?
                  <br />
                  <br />
                </h2>
                <p className="paragraphs">
                  In the realm of decentralized networks, node operators are
                  essential cogs that ensure the smooth and efficient
                  functioning of the system. Think of them as individual
                  computer systems or servers that hold a copy of the network's
                  data and its history. By doing so, they validate and relay
                  transactions, maintaining the integrity and security of the
                  entire network. With the RelyNet Testnet, we invite and
                  encourage individuals to become node operators, playing an
                  integral role in the evolution of our platform. And as a token
                  of our appreciation for their pivotal role, early contributors
                  hosting a node on RelyNet are rewarded for their efforts.
                </p>
              </div>
            </div>
          </div>

          <h2>
            <br />
            <br />
            Testnet Status
            <br />
            <br />
            <NodeUpdateWarning className="margin__top">
              <p>
                Registration for new Nodes suspended
              </p>
            </NodeUpdateWarning>
            <br />
          </h2>
          <p className="paragraphs">
            Our testnet is up and running, you can see the currently registered
            nodes by clicking here
            <a className="purple-text" href="/network/nodes">
              {" "}
              Relayz Network Nodes
            </a>
          </p>

        </div>
      </div>

      {showPauseNodesModal && (
        <PauseNodesModal handlePauseNodesModal={handlePauseNodesModal} />
      )}
    </>
  );
}
