import "./index.sass";
import { motion } from "framer-motion";

import heroImage from "../../assets/images/mockup.png";




const SectionImage = ({ className, src, alt, x, y }) => {
    return (
        <motion.img className={className}
            whileHover={{ scale: 1.05 }}
            initial={{ x: x, y: y }}
            whileInView={{ x: 0, y: 0, transition: { duration: 1.5 } }}
            viewport={{ once: true }}
            src={src}
            alt={alt}
        />
    )
}

export default function CtaMeet() {
    return (

        <section className="home-section home-description">
            <div className="hero__meet-wrapper">
                <h1 className="large-title purple-text">
                    Highly secure, private communications protocol for Web3
                </h1>
                <p className="subHeader__font">
                    Decentralized network and dApps
                </p>
                <div className="hero__mockup-button">
                    <a
                        className="home-description-cta-button"
                        href="https://meet.relayz.io"

                    >
                        Meet Now
                    </a>
                </div>
            </div>
            <SectionImage
                className="hero__mockup"
                initial="hidden"
                animate="visible"
                src={heroImage}
                alt="Relayz Hero Image laptop screen"
                x="2vw"
                y="3vw"
            />
        </section>

    );
}
