import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import App from "./App";
import Home from "./pages/Home";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Network from "./pages/Network";
import Disclaimer from "./components/Disclaimer";
import Downloads from "./pages/Network/DownloadsPath";
import Incentives from "./pages/Network/Incentives/Incentives";
import Testnet from "./pages/Network/Testnet";
import Overview from "./pages/Network/Overview";
import Nodes from "./pages/Network/Nodes/Nodes";
import Welcome from "./pages/Welcome";
import UserProfile from "./pages/NodeManagement/Profile/";
import UserLogin from "./pages/NodeManagement/Login";
import NodeManagement from "./pages/NodeManagement/";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import Invite from "./pages/Invite";
import NodeDownloadWarningPage from "./pages/NodeDownloadWarningPage";
import Meet from "./pages/Network/Meet";
import RelayzMeet from "./pages/RelayzMeet";
import NewsManagement from "./pages/NewsManagement";
import Products from "./pages/Network";

export default function AppRoutes() {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    const disclaimerAccepted = Cookies.get("disclaimerAccepted");

    if (!disclaimerAccepted) {
      setShowDisclaimer(true);
      Cookies.set("disclaimerAccepted", true, { expires: 7 });
    }
  }, []);

  return (
    <Router>
      <App>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />

          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="invite" element={<Invite />} />

          {/* Welcome referral page */}
          <Route path="welcome/:referralId?" element={<Welcome />} />
          <Route path="*" element={<Navigate to="/" />} />

          {/* Profile icon drop down menu */}
          <Route path="nodemanagement" element={<NodeManagement />}>
            <Route index element={<UserLogin />} />
            <Route 
              path="userprofile"
              element={<PrivateRoute element={<UserProfile />} />}
            />
            <Route path="login" element={<UserLogin />} />
          </Route>
  
          <Route path="newsmanagement" element={<NewsManagement />} />
          {/* video aplication */}
          <Route path="relayzmeet" element={<RelayzMeet />} />


          {/* product page */}
          <Route path="products" element={<Products />}>
            <Route index element={<Meet />} />
          </Route>  

          <Route path="Network" element={<Network />}>
            <Route index element={<Overview />} />

            <Route path="downloads" element={<Downloads />} />
            <Route path="incentive program" element={<Incentives />} />
            <Route path="relayz network" element={<Overview />} />
            <Route path="testnet" element={<Testnet />} />
            <Route path="nodes" element={<Nodes />} />
       
          </Route>

          <Route path="nodedownloadwarningpage" element={<NodeDownloadWarningPage />} />
        </Routes>

        {showDisclaimer && <Disclaimer />}
      </App>
    </Router>
  );
}
