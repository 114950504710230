import { NavLink } from "react-router-dom";
import warningIcon from "../../assets/icons/exclamation-circle.svg";

export default function NodeUpdateWarning ({ children }) {
  return (
    <NavLink 
      className="warning_notification"
      to="/network/downloads"
    >
      <img src={warningIcon} alt="Exclamation mark circle" />
      <>
        { children }
      </>
    </NavLink>
  );
}