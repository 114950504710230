export default function useGetSponsors() {
    const context = require.context("../assets/images/investors", false, /.png$/);
    const resources = [];
    const urls = [
        'https://bitscale.vc',
        'https://cogitent.ventures',
        'https://near.org',
        'https://galxe.com',
        'https://www.lucidblueventures.com',
        'https://www.geekcartel.org',
        'https://twitter.com/K24Ventures',
    ];
    context.keys().forEach((key, index) => {
        const fileName = key.replace('./', '');
        const resource = require(`../assets/images/investors/${fileName}`);
        resources.push({
            image: resource,
            url: urls[index]
        });

    });
    return resources;
}
