import {makeAutoObservable} from "mobx";
import {Wallet} from "../functions/near-wallet";
import AuthStore from "./AuthStore";
import {get, set} from 'idb-keyval';
import * as bip39 from "bip39";
const process = require('process');
const { parseSeedPhrase } = require('near-seed-phrase');

// eslint-disable-next-line require-jsdoc
class FunctionStore {
    constructor() {
        makeAutoObservable(this);
        //console.log(process.env)
    }    

    apiURL = process.env.API_SERVER_PATH || 'https://api.relayz.io/api/';
    contractName = "relayz-node.testnet";
    nearWallet = new Wallet({createAccessKeyFor: this.contractName});
    storageKey = '__keys_' + this.contractName;
    subtle = window.crypto.subtle;
    symmetricEncryptionKey = {
        name: "AES-CTR",
        counter: new Uint8Array(16),
        length: 128,
        use: ['encrypt', 'decrypt'],
    }

    // eslint-disable-next-line require-jsdoc
    handleError(err) {
        console.error(err)
    }

    // eslint-disable-next-line require-jsdoc
    async checkNearLogin() {
        // console.log("Checking near login...")
        // console.log("AuthStore before", AuthStore.getUserData());
        const account = await this.nearWallet.startUp();

        // console.log('CheckNear login', 'accountId', account.accountId, 'public key', account.publicKey);

        if (account) {
            AuthStore.setUserData("near", account.accountId, this.nearWallet.wallet.id, account.publicKey)
        } else {
            const ephemeralAccounts = await this.getAllKeys();

            if (ephemeralAccounts.length) {
                AuthStore.setUserData("ephemeral", ephemeralAccounts[0].public_key, null)
            }
        }
    }
    // eslint-disable-next-line require-jsdoc
    async loginWithNear() {
        await this.checkNearLogin();
        await this.nearWallet.signIn();
    }
    // eslint-disable-next-line require-jsdoc
    async createMeeting(meetingName, userName) {
        try {
            const {loginType, address, nearID} = AuthStore.getUserData();
            //console.log("user data when creating meeting: ", loginType, address, nearID)
            const response = await fetch(this.apiURL + "auth/login", {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    "user": {
                        "roomName": meetingName,
                        "displayName": userName
                    },
                    "wallet": {
                        "network": "testnet",
                        "name": nearID || null,
                        "address": address,
                        "chain": loginType,
                    }
                })
            });
            const result = await response.json();
            //console.log("request response", result);
            window.localStorage.setItem("meetingToken", result.token);
            if (result.token && result.data && result.data.redirectionUrl) {
                AuthStore.setUserJWT(result.token);
                AuthStore.setActiveMeetingLink(result.redirectionUrl);
            }
            window.open(result.data.redirectionUrl, "_self");
        } catch (e) {
            this.handleError(e)
            return null;
        }
    }
    // eslint-disable-next-line require-jsdoc
    async createEphemeralAccountAndRedirect(meetingName, userName) {
        const keyPair = await this.createNewQuickAccount();
        AuthStore.setUserData("ephemeral", keyPair.public_key, null);
        await this.createMeeting(meetingName, userName);
    }
    // eslint-disable-next-line require-jsdoc
    async clearNearData() {
      await this.removeAllKeys();
      window.localStorage.clear();
      window.location.reload();
    }

    // Keypair management (encryption, decryption)
    // eslint-disable-next-line require-jsdoc
    createKeyPair() {
        // this method creates an ed25519 keypair in bs58 by converting a bip39 mnemonic.
        const mnemonic = bip39.generateMnemonic();
        const { publicKey, secretKey } = parseSeedPhrase(mnemonic);
        return {
            publicKey,
            public_key: publicKey.toString().replace('ed25519:', ''),
            secretKey: secretKey.toString().replace('ed25519:', ''),
            mnemonic
        };
    }
    // eslint-disable-next-line require-jsdoc
    async encryptWithSymmetricKey(buffer) {
        // this method encrypts provided buffer with an assymetric key of the AES-CTR standard.
        const webCryptoKey = await this.subtle.generateKey(this.symmetricEncryptionKey, false, this.symmetricEncryptionKey.use);
        return {
            webCryptoKey,
            encryptedBuffer: await this.subtle.encrypt(
                this.symmetricEncryptionKey,
                webCryptoKey,
                buffer
            )
        }
    }

    async createNewQuickAccount() {
        // create a keypair with a mnemonic
        const keyPair = this.createKeyPair();
        // initialize text encoder
        const enc = new TextEncoder();
        // encrypt secret key
        const secretKeyBuffer = enc.encode(keyPair.secretKey);
        const secretKeyEncryptedBuffer = await this.encryptWithSymmetricKey(secretKeyBuffer);
        keyPair.secretKey = secretKeyEncryptedBuffer.encryptedBuffer;
        keyPair.webCryptoKeySecret = secretKeyEncryptedBuffer.webCryptoKey;
        // encrypt mnemonic
        const mnemonicBuffer = enc.encode(keyPair.mnemonic);
        const mnemonicEncryptedBuffer = await this.encryptWithSymmetricKey(mnemonicBuffer);
        keyPair.mnemonic = mnemonicEncryptedBuffer.encryptedBuffer;
        keyPair.webCryptoKeyMnemonic = mnemonicEncryptedBuffer.webCryptoKey;
        // add encrypted keypair to idb
        await this.addKey(keyPair);
        return keyPair;
    }

    async decryptWithSymmetricKey(webCryptoKey, buffer) {
        // this method decrypts a symmetrically-encrypted buffer with a provided webCryptoKey
        return await this.subtle.decrypt(
            this.symmetricEncryptionKey,
            webCryptoKey,
            buffer
        );
    }

    async getEphemeralAccountSecret(public_key) {
        const keyPair = await this.getKey(public_key);
        if (!keyPair) throw new Error("account not found");
        if (!keyPair.secretKey) throw new Error("secretKey not found")

        const decrypted = await this.decryptWithSymmetricKey(keyPair.webCryptoKeySecret, keyPair.secretKey)
        const dec = new TextDecoder();
        return dec.decode(decrypted);
    }

    async getEphemeralAccountMnemonic(public_key) {
        const keyPair = await this.getKey(public_key);
        if (!keyPair) throw new Error("account not found");
        if (!keyPair.mnemonic) throw new Error("mnemonic not found")

        const decrypted = await this.decryptWithSymmetricKey(keyPair.webCryptoKeyMnemonic, keyPair.mnemonic)
        const dec = new TextDecoder();
        return dec.decode(decrypted);
    }

    // IDB Keys management

    async getKey(public_key) {
        const keys = await get(this.storageKey) || []
        return keys.find((d) => d.public_key === public_key)
        
    }

    async getAllKeys() {
        return await get(this.storageKey) || []
    }

    async removeKey(public_key) {
        // get the keys from idb and remove the one matching this public_key
        const keys = await get(this.storageKey) || []
        const key = keys.splice(keys.findIndex((d) => d.public_key === public_key), 1)
        await set(this.storageKey, keys, key)
    }

    async removeAllKeys() {
        await set(this.storageKey, []);
    }

    async updateKey(public_key, prop, val) {
        try {
            const keys = await get(this.storageKey) || []
            const key = keys.find((d) => d.public_key === public_key)
            key[prop] = val
            await set(this.storageKey, keys, key)
        } catch (error) {
            throw new Error(error);
        }

    }

    async addKey(newKeyPair) {
        try {
            const keys = await get(this.storageKey) || []
            keys.push(newKeyPair)
            await set(this.storageKey, keys)
        } catch (error) {
            throw new Error(error);
        }
    }
}

const instanceFunctionStore = new FunctionStore();

export default instanceFunctionStore;
