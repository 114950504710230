import React, { useEffect } from 'react';
import "./index.sass";
import Footer from "../../components/Footer";

export default function RelayzMeet() {
    useEffect(() => {
        // When the component mounts, set the styles on the body
        document.body.style.margin = '0';
        document.body.style.padding = '0';
        document.body.style.height = '1000px';
        document.body.style.width = '100%';
        document.body.style.overflow = 'hidden';

        // Remember to revert this style changes when the component will unmount
        return () => {
            document.body.style.margin = '';
            document.body.style.padding = '';
            document.body.style.height = '';
            document.body.style.width = '';
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className="RelayzMeet">
            <iframe 
                src="https://meet.relayz.io" 
                className="fullScreenIframe"
                title="Relayz Meet Video Conference" // A descriptive title for the iframe content
            >
            </iframe>
            <Footer />
        </div>
    );
}
