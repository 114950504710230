import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';

function PrivateRoute({ element }) {
  // get the current status from the context
  const { isAuthenticated } = useAuthContext();

  // if the user is not authenticated and they try to access a private route, then he will be redirected to login page
  if(!isAuthenticated) {
    return (
      <Navigate to="/nodemanagement" replace />
    )
  }
  
  return element;
}

export default PrivateRoute;