import "./index.sass";
import React from "react";
import { Link } from "react-router-dom";
import nodereward from "../../../assets/images/nodereward.png";


export default function Incentives() {


  return (
    <div className="">
      <div className="network__article network__sections-incentive">
        <div className="node__reward">
          <div>
            <div
              id="incentive program"
            >
              <h2 className="purple-text network_incentives__join-hero">
                Testnet Incentive Program
              </h2>
            </div>
            <p className="paragraphs network__join-hero-subtitle">
              Join our Testnet Incentive Program and be rewarded for powering the growth of Relayz! Every day your node is active on our testnet, you're paving the way for a stronger, more reliable network.
              <br />
              <br />
              Click the button bellow to start today and earn
            </p>
        
            <Link to="/network/downloads" className="home-description-cta-button-navi">
              Run a Node
            </Link>
        
          </div>
          <div className="node__prop-mobile">
            <img src={nodereward} alt="Reward mobile" />
          </div>
        </div>
        <h2>
          10 RELY Tokens Daily
          <br /><br />
        </h2>
        <p className="paragraphs">
          Your daily contributions will be recognized with RELY tokens, accumulating directly in your balance.
          <br /><br />
        </p>

        <h2>
          Why is this program essential?
        </h2>
        <p className="paragraphs">
          <br />
          Your participation enables us to
          <br />
          <br />
          1) Expand and ensure a decentralized Relayz network
          <br />
          2) Foster increased community engagement
          <br />
          3) Detect and rectify any testnet issues
          <br />
          4) Evaluate varied node installation processes
          <br />
          5) Optimize the Relayz Meet and its underlying protocol for a flawless Mainnet launch
          <br />
          <br />
        </p>
        <h2>
          Getting Started
          <br /><br />
        </h2>
        <p className="paragraphs">
          Participation is straightforward, simply install and operate a node. Visit our <a className="purple-text" href="/network/downlowds">Downloads page</a> for detailed instructions. Whether you're a novice or a pro, as long as you meet our criteria, you're in. And while you can operate multiple nodes, we encourage you to diversify their locations globally.
        </p>
        <h2>
        <br />
          Reward Distribution
          <br /><br />
        </h2>
        <p className="paragraphs">
        All rewards will be distributed during our Token Generation Event (TGE). While we're gearing up for the Mainnet launch, specific dates are yet to be announced. For a clearer picture of our journey and projected launch dates, be sure to check out the updated roadmap on our <a className="purple-text" href="/about">About page</a>.
             </p>
        <h2>
        <br />
          Bug Reporting
          <br /><br />
        </h2>
        <p className="paragraphs">
          Found an issue? Report it on our <a className="purple-text" href="https://discord.com/invite/DuEJEMss9G" target="blank">Discord channel</a> under “#Testnet-Bugs” and stand a chance to earn additional rewards.
        </p>
        <h2>
        <br />
          Continued Participation Rewards
          <br /><br />
        </h2>
        <p className="paragraphs">
          Loyalty pays! Nodes that transition from our testnet to the mainnet will be eligible for further rewards, contingent on their service provision ratings to the network.
        </p>

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
